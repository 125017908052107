import {NgModule} from '@angular/core';
import {RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './auth/services/auth-guard.service';
import {ClientStorageResolver} from "./shared/resolvers/client-storage-resolver";
import { CustomReuseStrategy } from './app-routing-reuse-strategy';


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule)
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    resolve: [ClientStorageResolver],
    loadChildren: () => import('./core/core.module').then(mod => mod.CoreModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  providers: [{ provide: RouteReuseStrategy, useClass: CustomReuseStrategy }],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
