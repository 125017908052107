import {VenuesHealth, VenueType} from '../../models/venues.model'
import {EntitySyncStatus} from '../../models/sync-status.model';

export const mockedVenuesHealth: VenuesHealth = {
  id: 1,
  venueName: "Lab 2.0",
  fabricsHealth: 22,
  devicesHealth: 84,
  address: "Derech Hevron 24",
  lat: 31.762605,
  long: 35.212267,
  numDevices: 100,
  numFabrics: 3,
  syncStatus: EntitySyncStatus.SUCCESS,
  kpiMissingDevicesNo: 0,
  type: VenueType.CAMPUS,
  anomalySeverityCount: null
}
export const venueSyncStatus: any = {
  "venueId": 138361960,
  "tenantId": 138359992,
  "syncStatus": "NO_DATA_AVAILABLE",
  "createdAt": 1593974982574,
  "updatedAt": 1594549241349
}

export const venuesSummary = [
  {venueId: 1, venueName: 'Central Office', deviceCount: 10, SSIDCount: 10, vlanCount: 5},
  {venueId: 2, venueName: 'Noth Office', deviceCount: 10, SSIDCount: 10, vlanCount: 5},
  {venueId: 3, venueName: 'South Office', deviceCount: 10, SSIDCount: 10, vlanCount: 5},
  {venueId: 4, venueName: 'Another Office', deviceCount: 10, SSIDCount: 10, vlanCount: 5},
  {venueId: 5, venueName: 'My Best Office', deviceCount: 10, SSIDCount: 10, vlanCount: 5},
  {venueId: 6, venueName: 'My Another Office', deviceCount: 10, SSIDCount: 10, vlanCount: 5},
  {venueId: 7, venueName: 'My Worst Office', deviceCount: 10, SSIDCount: 10, vlanCount: 5},
  {venueId: 8, venueName: 'My Some Office', deviceCount: 10, SSIDCount: 10, vlanCount: 5},
];
