import {LegacyClient, ConnectedClientType, ClientDevice, FailedConnection} from '../../models/clients.model'
import {ReqInfoMocked} from './req-info.mocked';
import {Page} from '../../models/page.model';
import {SingleLinkType, SingleLinkStatus} from '../../models/single-link.model';
import {SingleDeviceType} from '../../models/single-device.model';

export const failedClients: Page<FailedConnection> = {
  total: 1,
  data: [
    {
      clientMac: "12:3f:34",
      device: "f",
      deviceId: 10,
      failureStep: "Enter wrong password",
      id: "2334852398457423589",
      kpiName: "clients",
      networkId: "1",
      organizationId: "1",
      serial: "Q2PD-7VUL-M24F",
      ssidNumber: 4,
      tenantId: 1,
      time: "now",
      ts: 12,
      type: "Mobile",
      vlan: 20
    }
  ]
}

// ts: number;
// type: string;
// vlan: number;
const timeExampleForClient: Date = new Date();

export const multiVendorClients: Page<any> = {
  data: [
    {
      "device": null,
      "connectionProperties": {
        "connectionType": "WIRED",
        "connectionStatus": "Unknown",
        "sent": null,
        "received": null,
        "rssi": 0,
        "ssid": null,
        "vlan": "NA"
      },
      "deviceId": null,
      "mac": "24:41:8c:9d:0e:4a",
      "online": false,
      "firstSeen": 0,
      "lastSeen": 0,
      "os": "",
      "connectedClientType": "UNKNOWN",
      "clientName": "NA",
      "unknown": true
    },
    {
      "deviceId": 94,
      "device": {
        "id": 94,
        "type": "AccessPoint",
        "name": "Yuval's AP",
        "description": "Yuval's AP, Q2PD-7VW3-RRZU, ",
        "networkId": null,
        "createdAt": 1593951825599,
        "venueId": 6,
        "tenantId": null,
        "model": "MR33",
        "serialNumber": "Q2PD-7VW3-RRZU",
        "lat": 31.76703,
        "lon": 35.22581,
        "vendorName": "meraki",
        "properties": {
          "0": {
            "interface": "WAN 1",
            "status": "Active",
            "ip": "192.168.128.8",
            "gateway": "192.168.128.1",
            "publicIp": "84.228.120.148",
            "dns": "192.168.128.1",
            "usingStaticIp": false
          },
          "address": "Hebron Road 24, Jerusalem",
          "tags": " recently-added rnd ",
          "url": "https://n213.meraki.com/Lab02-wireless/n/5HS2yavd/manage/nodes/new_list/247165640470076",
          "firmware": "wireless-26-6-1",
          "floorPlanId": null,
          "ports": {
            "wired0": {
              "lldp": {
                "sourcePort": "wired0",
                "systemName": "Meraki MS210-24 - Switch 1 Lab 02",
                "managementAddress": "192.168.128.2",
                "portId": "3"
              },
              "cdp": {
                "sourcePort": "wired0",
                "deviceId": "e0cbbcaad660",
                "address": "192.168.128.2",
                "portId": "Port 3"
              }
            }
          },
          "sourceMac": "e0:cb:bc:33:6e:3c"
        }
      },
      "connectionProperties": {
        "connectionType": "WIRELESS",
        "connectionStatus": "OK",
        "sent": 25,
        "received": 62,
        "rssi": 0,
        "ssid": "Guests",
        "vlan": "20"
      },
      "mac": "88:11:96:ab:3d:29",
      "online": true,
      "firstSeen": 1592143623000,
      "lastSeen": 1592748747000,
      "os": "Android",
      "connectedClientType": "MOBILE",
      "clientName": "HUAWEI_MediaPad_M5_lite_1",
      "unknown": false
    }
  ],
  total: 2
}

export const clientsDevices: ClientDevice[] = [
  {
    deviceId: 11,
    device: {
      id: 11,
      type: SingleDeviceType.AccessPoint,
      name: "MR42_1",
      description: "MR42_1, Q2KD-H3BP-4DQJ",
      networkId: "N_706502191543749363",
      createdAt: 1579726957361,
      venueId: 6,
      model: "MR42",
      serialNumber: "ABCD-FG",
      lat: 37.418095,
      lon: -122.09853,
      vendorName: "meraki",
      properties: {
        0: {
          interface: "WAN 1",
          status: "Active",
          ip: "192.168.149.2",
          gateway: "192.168.149.254",
          publicIp: "81.218.0.108",
          dns: "192.117.235.235",
          usingStaticIp: false
        },
        tags: "recently-added",
        firmware: "wireless-25-14",
        floorPlanId: null,
        ports: {
          wired0: {
            cdp: {
              sourcePort: "wired0",
              deviceId: "e0553dc158c8",
              address: "192.168.149.1",
              portId: "Port 0"
            }
          }
        },
        sourceMac: "88:15:44:ab:4a:95"
      }
    },
    connectionProperties: {
      connectionStatus: SingleLinkStatus.OK,
      connectionType: SingleLinkType.Wireless,
      rssi: -75,
      sent: 1578212469,
      received: 1578212469,
      ssid: "Meir Hospitle",
      vlan: "LAN-270",
    },
    mac: "88:15:44:ab:4a:95",
    online: true,
    os: "1234",
    manufacturer: "1234",
    unknown: false, firstSeen: "1578212469",
    lastSeen: "1578212469",
    connectedClientType: ConnectedClientType.MOBILE,
    clientName: "Android 1"
  },
  {
    deviceId: 11,
    device: {
      id: 11,
      type: SingleDeviceType.AccessPoint,
      name: "MR42_1",
      description: "MR42_1, Q2KD-H3BP-4DQJ",
      networkId: "N_706502191543749363",
      createdAt: 1579726957361,
      venueId: 6,
      model: "MR42",
      serialNumber: "ABCD-FG",
      lat: 37.418095,
      lon: -122.09853,
      vendorName: "meraki",
      properties: {
        0: {
          interface: "WAN 1",
          status: "Active",
          ip: "192.168.149.2",
          gateway: "192.168.149.254",
          publicIp: "81.218.0.108",
          dns: "192.117.235.235",
          usingStaticIp: false
        },
        tags: "recently-added",
        firmware: "wireless-25-14",
        floorPlanId: null,
        ports: {
          wired0: {
            cdp: {
              sourcePort: "wired0",
              deviceId: "e0553dc158c8",
              address: "192.168.149.1",
              portId: "Port 0"
            }
          }
        },
        sourceMac: "88:15:44:ab:4a:95"
      }
    },
    connectionProperties: {
      connectionStatus: SingleLinkStatus.OK,
      connectionType: SingleLinkType.Wireless,
      rssi: -84,
      sent: 1578212469,
      received: 1578212469,
      ssid: "Meir Hospitle",
      vlan: "LAN-270",
    },
    mac: "88:15:44:ab:4a:95",
    online: true,
    os: "1234",
    manufacturer: "1234",
    unknown: false,
    firstSeen: "1578212469",
    lastSeen: "1578212469",
    connectedClientType: ConnectedClientType.MOBILE,
    clientName: "Android 2"
  },
  {
    deviceId: 11,
    device: {
      id: 11,
      type: SingleDeviceType.AccessPoint,
      name: "MR42_1",
      description: "MR42_1, Q2KD-H3BP-4DQJ",
      networkId: "N_706502191543749363",
      createdAt: 1579726957361,
      venueId: 6,
      model: "MR42",
      serialNumber: "ABCD-FG",
      lat: 37.418095,
      lon: -122.09853,
      vendorName: "meraki",
      properties: {
        0: {
          interface: "WAN 1",
          status: "Active",
          ip: "192.168.149.2",
          gateway: "192.168.149.254",
          publicIp: "81.218.0.108",
          dns: "192.117.235.235",
          usingStaticIp: false
        },
        tags: "recently-added",
        firmware: "wireless-25-14",
        floorPlanId: null,
        ports: {
          wired0: {
            cdp: {
              sourcePort: "wired0",
              deviceId: "e0553dc158c8",
              address: "192.168.149.1",
              portId: "Port 0"
            }
          }
        },
        sourceMac: "88:15:44:ab:4a:95"
      }
    },
    connectionProperties: {
      connectionStatus: SingleLinkStatus.OK,
      connectionType: SingleLinkType.Wireless,
      rssi: -103,
      sent: 1578212469,
      received: 1578212469,
      ssid: "Meir Hospitle",
      vlan: "LAN-270",
    },
    mac: "88:15:44:ab:4a:95",
    online: true,
    os: "1234",
    manufacturer: "1234",
    unknown: false,
    firstSeen: "1578212469",
    lastSeen: "1578212469",
    connectedClientType: ConnectedClientType.MOBILE,
    clientName: "Android 3"
  },
  {
    deviceId: 9,
    device: {
      id: 9,
      type: SingleDeviceType.AccessPoint,
      name: "MR42_1",
      description: "MR42_1, Q2KD-H3BP-4DQJ",
      networkId: "N_706502191543749363",
      createdAt: 1579726957361,
      venueId: 6,
      model: "MR42",
      serialNumber: "Q2MD-9G3E-X8UL",
      lat: 37.418095,
      lon: -122.09853,
      vendorName: "meraki",
      properties: {
        0: {
          interface: "WAN 1",
          status: "Active",
          ip: "192.168.149.2",
          gateway: "192.168.149.254",
          publicIp: "81.218.0.108",
          dns: "192.117.235.235",
          usingStaticIp: false
        },
        tags: "recently-added",
        firmware: "wireless-25-14",
        floorPlanId: null,
        ports: {
          wired0: {
            cdp: {
              sourcePort: "wired0",
              deviceId: "e0553dc158c8",
              address: "192.168.149.1",
              portId: "Port 0"
            }
          }
        },
        sourceMac: "88:15:44:ab:4a:95"
      }
    },
    connectionProperties: {
      connectionStatus: SingleLinkStatus.OK,
      connectionType: SingleLinkType.Wireless,
      rssi: -111,
      sent: 1578212469,
      received: 1578212469,
      ssid: "Meir Hospitle",
      vlan: "LAN-270",
    },
    mac: "88:15:44:ab:4a:95",
    online: true,
    os: "1234",
    manufacturer: "1234",
    unknown: false,
    firstSeen: "1578212469",
    lastSeen: "1578212469",
    connectedClientType: ConnectedClientType.LAPTOP,
    clientName: "Macook Pro 1"
  }
]


export function getClients(reqInfo: ReqInfoMocked): Page<LegacyClient> {
  return multiVendorClients;
}

export function getFailedClients(reqInfo: ReqInfoMocked) {
  return null;
}

export const MOCKED_CLIENTS_FOR_MEIR_VENUE = [{
  "deviceId": 296718007,
  "device": {
    "id": 296718007,
    "type": SingleDeviceType.AccessPoint,
    "name": "MX64W_1",
    "description": null,
    "networkId": null,
    "createdAt": 1615292105806,
    "venueId": 251083075,
    "tenantId": null,
    "model": "MX64W",
    "serialNumber": "Q2MN-2F9B-AKTM",
    "lat": 37.418095,
    "lon": -122.09853,
    "vendorName": "meraki",
    "intPointId": null,
    "properties": {
      "0": {
        "interface": "WAN 1",
        "status": "Active",
        "ip": "212.179.195.34",
        "gateway": "212.179.195.33",
        "publicIp": "212.179.195.34",
        "dns": "192.168.99.1, 8.8.8.8",
        "usingStaticIp": true
      },
      "1": {
        "interface": "WAN 2",
        "status": "Not connected"
      },
      "address": "",
      "wan1Ip": "212.179.195.34",
      "wan2Ip": null,
      "url": "https://n311.meraki.com/rahat-appliance/n/rZ9iEb3e/manage/nodes/new_list/149624919533504",
      "firmware": "wired-14-40",
      "floorPlanId": null,
      "ports": {
        "wan0": {
          "lldp": {
            "sourcePort": "wan0",
            "portId": "1001"
          }
        },
        "port1": {
          "cdp": {
            "sourcePort": "port1",
            "deviceId": "e0553d849ee5",
            "address": "192.168.208.10",
            "portId": "Port 0"
          },
          "lldp": {
            "sourcePort": "port1",
            "systemName": "rahat-in",
            "managementAddress": "192.168.208.10",
            "portId": "0"
          }
        },
        "port2": {
          "cdp": {
            "sourcePort": "port2",
            "deviceId": "e0553d849ee4",
            "address": "192.168.208.24",
            "portId": "Port 0"
          },
          "lldp": {
            "sourcePort": "port2",
            "systemName": "rahat-out",
            "managementAddress": "192.168.208.24",
            "portId": "0"
          }
        },
        "port3": {
          "lldp": {
            "sourcePort": "port3",
            "systemName": "Meraki MS225-24P - rahat - switch",
            "managementAddress": "192.168.108.2",
            "portId": "24"
          },
          "cdp": {
            "sourcePort": "port3",
            "deviceId": "e0553dddc5e0",
            "address": "192.168.108.2",
            "portId": "Port 24"
          }
        }
      },
      "sourceMac": "88:15:44:2d:a7:c0"
    }
  },
  "connectionProperties": {
    "connectionType": "WIRED",
    "connectionStatus": "OK",
    "sent": null,
    "received": null,
    "rssi": 0,
    "ssid": null,
    "vlan": "camera"
  },
  "mac": "e0:55:3d:84:9e:e4",
  "online": true,
  "firstSeen": 1577008424000,
  "lastSeen": 1620027550000,
  "os": "Meraki OS",
  "manufacturer": "Cisco Meraki",
  "connectedClientType": ConnectedClientType.MultiLAPTOP,
  "clientName": "rahat-out-e0553d849ee4",
  "unknown": false
}]
