import { ReqInfoMocked } from './req-info.mocked';
import { HealthIndicator } from '../../models/health.model';
import { FabricsHealth, FabricStatus } from '../../models/fabrics.model';

export function getFabricsActions(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
    const actions = [
        {
            "id": 1,
            health: 50,
            name: "EmployeesRadius",
            category: 4,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Operational",
            description: "All attempts to connect to EmployeesRadius fabric failed",
            status: "Open",
            affectedFabrics: 4,
            statusIndicator: HealthIndicator.MOST_SEVERE
        },
        {
            "id": 2,
            health: 90,
            name: "netop guest",
            category: 4,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Operational",
            description: "Fabric netop guest is not used for 10 days",
            status: "Open",
            affectedFabrics: 6,
        },
        {
            "id": 3,
            health: 90,
            name: "Open Wifi",
            category: 4,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Operational",
            description: "Fabric Open Wifi is not used for 3 days",
            status: "Acknowledged",
            affectedFabrics: 4,
        },
        {
            "id": 4,
            health: -1,
            name: "Open Wifi",
            category: 5,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Security",
            description: "Malware download was detected on Fabric Open Wifi by client “Zoei’s Samsung S10",
            status: "Open",
            affectedFabrics: 4,
        },
    ];
    return actions;
}

export function getFabricsHealth(reqInfo: ReqInfoMocked, urlParams: Array<string>): FabricsHealth[] {
    const health = [
        {
            id: 1,
            fabricName: "fabricsMocked 1",
            fabricHealth: 10,
            fabricState: FabricStatus.COMPLETE,
            statusIndicator: HealthIndicator.MOST_SEVERE, // e.g. "MOST_SEVER"
            parentVenueName: "venue 1",
            numDevices: 10
        },
        {
            id: 2,
            fabricName: "fabricsMocked 2",
            fabricHealth: 15,
            fabricState: FabricStatus.COMPLETE,
            parentVenueName: "venue 1",
            numDevices: 10
        },
        {
            id: 3,
            fabricName: "fabricsMocked 3",
            fabricHealth: 33,
            fabricState: FabricStatus.INCOMPLETE,
            parentVenueName: "venue 2",
            numDevices: 20
        },
        {
            id: 4,
            fabricName: "fabricsMocked 4",
            fabricHealth: 90,
            fabricState: FabricStatus.COMPLETE,
            parentVenueName: "venue 1",
            numDevices: 30
        },
        {
            id: 5,
            fabricName: "fabricsMocked 5",
            fabricHealth: 80,
            fabricState: FabricStatus.COMPLETE,
            parentVenueName: "venue 3",
            numDevices: 11
        },
        {
            id: 6,
            fabricName: "fabricsMocked 6",
            fabricHealth: -1,
            fabricState: FabricStatus.NoWwwConnectivity,
            parentVenueName: "venue 4",
            numDevices: 22
        },
    ];
    return health;
}
