import { VPNConnectionStatus, VPNTraffic, VPNStatus } from '../../models/vpn.model';
import {DeviceState} from "../../models/devices.model";

export const vpnMocked: any[] = [];

export const trafficVPN: VPNTraffic[] = [
    {
        firstSiteId: 1,
        firstSiteTraffic: {
            sent: 80,
            recv: 55
        },
        secondSiteId: 2,
        secondSiteTraffic: {
            sent: 55,
            recv: 80
        }
    }
];

export const vpnStatus: VPNStatus = {
    vpnSitesStatus: {
        91: DeviceState.Online,
        92: DeviceState.Offline,
        93: DeviceState.Alerting,
        94: DeviceState.Online,
        95: DeviceState.Unknown,
        96: DeviceState.Online,
        101: DeviceState.Alerting,
        121: DeviceState.Online,
        122: DeviceState.Alerting,
        123: DeviceState.Online
    },
    vpnConnectionsStatus: {
        1: VPNConnectionStatus.REACHABLE,
        2: VPNConnectionStatus.REACHABLE,
        3: VPNConnectionStatus.REACHABLE,
        4: VPNConnectionStatus.REACHABLE,
        5: VPNConnectionStatus.UNREACHABLE,
        6: VPNConnectionStatus.REACHABLE,
        7: VPNConnectionStatus.UNREACHABLE,
        8: VPNConnectionStatus.REACHABLE,
        9: VPNConnectionStatus.UNREACHABLE,
    }
}
