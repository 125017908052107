import {ReqInfoMocked} from './req-info.mocked';

export function getHealthTrend(reqInfo: ReqInfoMocked) {
  //let entityType = (reqInfo as any).query.get("queryType")[0];
  let i = Math.round(Math.random() * (10 * 0.25));
  let funcs = [(n) => Math.sin(n) * (i + 1), (n) => Math.cos(n) * (i + 1), (n) => Math.sin(n) * (i + 1), (n) => Math.cos(n) * (i + 1)];
  // let trendData = this.generateData(funcs[i])
  let dataTrend = this.generateData((n) => Math.abs(funcs[i](n)) % 1);
  let dataTotalTrend = this.generateData(() => {
    return 1.2
  });

  // let totalData = this.generateData((y) => { return Math.abs(Math.sin(y)) / 2 })
  // let closedData = this.generateData((y) => { return Math.abs(Math.sin(y)) + 0.5 })
  // return {
  //   total: totalData,
  //   closed: closedData
  // }
  return {
    total: dataTotalTrend,
    unhealthy: dataTrend
  }

}

export function getActionsTrend(reqInfo: ReqInfoMocked) {
  let totalData = this.generateData((y) => {
    return Math.abs(Math.sin(y)) / 2
  })
  let closedData = this.generateData((y) => {
    return Math.abs(Math.sin(y)) + 0.5
  })
  return {
    total: totalData,
    closed: closedData
  }
}

export function getKpiTrend(reqInfo: ReqInfoMocked) {
  let i = Math.round(Math.random() * (10 * 0.25));
  let funcs = [(n) => Math.sin(n) * (i + 1), (n) => Math.cos(n) * (i + 1), (n) => Math.sin(n) * (i + 1), (n) => Math.cos(n) * (i + 1)];
  // let trendData = this.generateData(funcs[i])
  let dataTrend = this.generateData((n) => Math.abs(funcs[i](n)) % 1);
  return dataTrend;
  //[{datetime:Date,value:number}]
}


//Mocked Data for checking staqcked data
export const mockedClalitSplit = null

export function getKpiGrouped(reqInfo: ReqInfoMocked) {
  return null;

}

export function getSplitKpiTrend(reqInfo: ReqInfoMocked) {
  return null;
}



