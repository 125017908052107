export const ssids = [
    { ssidId: 1, ssidName: "Guest Network 1", ssidDescription: "Encriptions... VLAN Tagging ... " },
    { ssidId: 2, ssidName: "Guest Network 2", ssidDescription: "Encriptions... VLAN Tagging ... " },
    { ssidId: 3, ssidName: "Guest Network 3", ssidDescription: "Encriptions... VLAN Tagging ... " },
    { ssidId: 4, ssidName: "Guest Network 4", ssidDescription: "Encriptions... VLAN Tagging ... " },
    { ssidId: 5, ssidName: "Guest Network 5", ssidDescription: "Encriptions... VLAN Tagging ... " },
    { ssidId: 6, ssidName: "Guest Network 6", ssidDescription: "Encriptions... VLAN Tagging ... " },
    { ssidId: 7, ssidName: "Guest Network 7", ssidDescription: "Encriptions... VLAN Tagging ... " },
    { ssidId: 8, ssidName: "Guest Network 8", ssidDescription: "Encriptions... VLAN Tagging ... " },
];

export const vlans = [
    { vlanId: 10, vlanName: 'VLAN 100', vlanDescription: "Subnet..." },
    { vlanId: 20, vlanName: 'VLAN 110', vlanDescription: "Subnet..." },
    { vlanId: 30, vlanName: 'VLAN 120', vlanDescription: "Subnet with very long description ,use css elipsis here " },
];