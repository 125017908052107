import {KeycloakService} from "keycloak-angular";
import {from} from 'rxjs';
import {switchMap} from "rxjs/operators";
import {ConfigInitService} from "./config-init.service";
import {UrlResolverService} from "../../../shared/services/url-resolver-service";

export function initializeKeycloak(
  keycloak: KeycloakService,
  configService: ConfigInitService,
  urlResolver: UrlResolverService,
) {
  return () =>
    configService.getConfig()
      .pipe(
        switchMap<any, any>((config) => {
          return from(keycloak.init({
            config: {
              url: urlResolver.getKeycloakUrl(config.KEYCLOAK_URL),
              realm: config.KEYCLOAK_REALM,
              clientId: config.KEYCLOAK_CLIENT_ID,

            },
            bearerExcludedUrls: ['/assets'],
            loadUserProfileAtStartUp: true,
            initOptions: {
              onLoad: 'login-required',
              checkLoginIframe: false
              // silentCheckSsoRedirectUri: `${window.location.origin}/assets/key-cloak-config/silent-check-sso.html`,
            },
          }))
        })
      ).toPromise();
}
