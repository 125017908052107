import { ReqInfoMocked } from './req-info.mocked';

export function getSingleTenant(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
    const tenants = [
        { "id": 1, "name": "tenant 1", "lineOfBusiness": "this is my business", "numOfVenues": 0, "numOfTasks": 0, "numOfFabrics": 0 },
        { "id": 2, "name": "tenant 2", "lineOfBusiness": "this is my sec business", "numOfVenues": 0, "numOfTasks": 0, "numOfFabrics": 0 },
        { "id": 3, "name": "tenant 3", "lineOfBusiness": "this is my third business", "numOfVenues": 0, "numOfTasks": 0, "numOfFabrics": 0 },
        { "id": 4, "name": "tenant 4", "lineOfBusiness": "this is my third business", "numOfVenues": 0, "numOfTasks": 0, "numOfFabrics": 0 },
        { "id": 5, "name": "tenant 5", "lineOfBusiness": "this is my third business", "numOfVenues": 0, "numOfTasks": 0, "numOfFabrics": 0 }
    ];

    let tenantId: number = Number.parseInt(urlParams[1]);
    //debugger
    let singleTenant = tenants.find((t) => t.id == tenantId)
    if (!singleTenant) {
        return tenants[0]
    }
    return singleTenant
}

export function postTenant(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
    return {
        "id": 10, "name": "tenant 5",
        "organization": 1,
        "lineOfBusiness": "this is my new business",
        "numOfVenues": 0, "numOfTasks": 0, "numOfFabrics": 0
    }
}

export const tenantsMocked = [
    { "id": 1, "name": "tenant 1", "lineOfBusiness": "this is my business", "numOfVenues": 0, "numOfTasks": 0, "numOfFabrics": 0 },
    { "id": 2, "name": "tenant 2", "lineOfBusiness": "this is my sec business", "numOfVenues": 0, "numOfTasks": 0, "numOfFabrics": 0 },
    { "id": 3, "name": "tenant 3", "lineOfBusiness": "this is my third business", "numOfVenues": 0, "numOfTasks": 0, "numOfFabrics": 0 }
];
export const tenantSyncStatus = {
    SYNC: 2,
    FAILED: 3,
    SUCCESS: 4,
    ANALYZING: 4,
    PENDING: 14,
    NO_DATA_AVAILABLE: 7
}
