import { Journal, SchedulingState } from '../../models/journal.model';

export const MockedJournal: Journal = {
    last_backup: 1578212469,
    next_backup: 1578212469,
    backup_enabled: true,
    entries: [{ id: 2, time: 1578212469, status: "In progress", summary: "Looking good" }]
}

export const scheduleBackupStatus: boolean = false;
export const schedulingState: SchedulingState = {
    cron: '0 0 1 * * ? *',
    tenantId: 1,
    enabled: true
}