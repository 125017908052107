import { ReqInfoMocked } from './req-info.mocked';
import { HealthIndicator } from '../../models/health.model';
import { DevicesHealth } from '../../models/devices.model';
import { Page } from '../../models/page.model';

export function getDevicesActions(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
    const actions = [
        {
            "id": 1,
            health: 50,
            name: "Lab02 AP1",
            category: 4,
            categoryName: "Operational",
            description: "Detected 25 reboots on  Lab02 AP1 in last 2 hours",
            status: "Open",
            affectedFabrics: 2,
            statusIndicator: HealthIndicator.MOST_SEVERE
        },
        {
            "id": 2,
            health: 80,
            name: "Yuval's AP",
            category: 4,
            categoryName: "Operational",
            description: "Access Point Yuval's AP is near maximum capacity",
            status: "Open",
            affectedFabrics: 2,
            statusIndicator: HealthIndicator.MOST_SEVERE
        }, {
            "id": 4,
            health: 50,
            name: "Lab02 AP1",
            category: 4,
            categoryName: "Operational",
            description: "High temperature alert received on Lab02 AP1",
            status: "Completed",
            affectedFabrics: 0,
        },
        {
            "id": 3,
            health: -1,
            name: "Switch1 Lab02",
            category: 4,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Operational",
            description: "Unusual load detected on Switch1 Lab02, 40% above average",
            status: "Acknowledged",
            affectedFabrics: 6,
            statusIndicator: HealthIndicator.MOST_SEVERE
        },
    ];
    return actions;

}

export function getDevicesHealth(reqInfo: ReqInfoMocked, urlParams: Array<string>): any[] {

    const health = [
        {
            id: 1,
            deviceName: "Meraki Shmeraki",
            health: 10,
            vendorName: "Meraki",
            model: "Meraki S10",
            affectedFabrics: 10,
            statusIndicator: HealthIndicator.MOST_SEVERE // e.g. "MOST_SEVER"
        },
        {
            id: 2,
            deviceName: "Meraki Psachim",
            health: 12,
            vendorName: "Meraki",
            model: "Meraki S10",
            affectedFabrics: 20,
            statusIndicator: HealthIndicator.MOST_SEVERE // e.g. "MOST_SEVER"
        },
        {
            id: 3,
            deviceName: "Meraki Brachot",
            health: 30,
            vendorName: "Meraki",
            model: "Meraki S10",
            affectedFabrics: 12,
        },
        {
            id: 4,
            deviceName: "Meraki Beitza",
            health: 21,
            vendorName: "Meraki",
            model: "Meraki S10",
            affectedFabrics: 30,
            statusIndicator: HealthIndicator.MOST_SEVERE // e.g. "MOST_SEVER"
        },
        {
            id: 5,
            deviceName: "Meraki Sanhedrin",
            health: 61,
            vendorName: "Meraki",
            model: "Meraki S10",
            affectedFabrics: 45,
        },
        {
            id: 1,
            deviceName: "Meraki Shmeraki",
            health: 71,
            vendorName: "Meraki",
            model: "Meraki S10",
            affectedFabrics: 23,
        },
        {
            id: 6,
            deviceName: "Meraki Baba Batra",
            health: -1,
            vendorName: "Meraki",
            model: "Meraki S10",
            affectedFabrics: 35,
        },
    ];
    return health;
}
