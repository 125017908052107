import { Bandwidth, BandwidthUtilization } from '../../models/bandwidth.model';
import { ReqInfoMocked } from './req-info.mocked';

export const tenantBandwidth: Bandwidth[] = [
    {
        acquiredDownlink: 34,
        acquiredUplink: 99,
        venueId: 3
    },
    {
        acquiredDownlink: 53,
        acquiredUplink: 66,
        venueId: 5
    },
    {
        acquiredDownlink: 84,
        acquiredUplink: 22,
        venueId: 7
    },
    {
        acquiredDownlink: 91,
        acquiredUplink: 33,
        venueId: 9
    },
    {
        acquiredDownlink: 58,
        acquiredUplink: 52,
        venueId: 11
    },
    {
        acquiredDownlink: 12,
        acquiredUplink: 88,
        venueId: 13
    },
]

export const venueBandwidth: Bandwidth = {
    acquiredDownlink: 59,
    acquiredUplink: 23,
    venueId: 89
}

export function postVenueBandwidth(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
    return venueBandwidth;
}

export const venueUtilization: BandwidthUtilization = {
    recvTotal: 34,
    sentTotal: 20,
    time: "1578212469"
}
