import { ReqInfoMocked } from './req-info.mocked';
import { Hierarchy } from '../../models/hierarchy.model';
import { EntityType } from '../../models/entity-type.enum';

export function getHierarcy(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
    // this.href.substring(this.href.lastIndexOf('/') + 1)
    var pageURL = window.location.pathname;
    let urlParts = pageURL.split('/')
    var lastURLSegment = urlParts.length > 0 ? pageURL.split('/')[1] : '';
    //let entityType = this.route.url[this.route.url.length - 1].path

    let lastEntities = {
        [EntityType.FABRIC.toLowerCase()]: {
            id: 1,
            name: "Igor 1",
            type: EntityType.FABRIC
        },
        [EntityType.VENUE.toLowerCase()]: {
            id: 1,
            name: "Igor 2",
            type: EntityType.VENUE
        },
        [EntityType.DEVICE.toLowerCase()]: {
            id: 1,
            name: "Igor 3",
            type: EntityType.DEVICE
        },
        [EntityType.CLIENT.toLowerCase()]: {
            id: 1,
            name: "Igor 3",
            type: EntityType.CLIENT
        },
    }
    const hierarcy: Hierarchy = [{
        id: 1,
        name: "Netop",
        type: EntityType.ORGANIZATION
    },
    {
        id: 2,
        name: "Sub org",
        type: EntityType.ORGANIZATION
    },
    {
        id: 1,
        name: "netop",
        type: EntityType.TENANT
    },
    {
        id: 1,
        name: "Clalit",
        type: EntityType.VENUE
    }
    ]
    // Check if the first part of the url is the actual name of the entity type
    //debugger
    // if (lastURLSegment && lastEntities[lastURLSegment.toLowerCase()]) {
    //     let he = [...hierarcy, lastEntities[lastURLSegment.toLowerCase()]]
    //     return he
    // }

    // If the first part is not the entity type - got other the url backwords till we find the type
    const entityType = urlParts.slice(0).reverse().find(step => !!lastEntities[step.toLowerCase()])
    if (entityType) {
        let he = [...hierarcy, lastEntities[entityType]]
        return he
    }

    return hierarcy
}
