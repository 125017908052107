import {Injectable, Injector} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Auth0Service} from '../../shared/services/auth0.service';
import {environment} from 'src/environments/environment';
import {AuthMethod} from "../../../environments/environment.model";
import {KeycloakAuthGuard, KeycloakService} from "keycloak-angular";
import {AuthStorageService} from "../auth-storage.service";
import {UsersService} from "../../shared/services/rest-services/users.service";


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService extends KeycloakAuthGuard  {
  constructor(
    private injector: Injector,
    readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private authService: Auth0Service,
    private readonly authStorageService: AuthStorageService,
    private readonly userService: UsersService,
  ) {
    super(router, keycloak);
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (environment.authMethod === AuthMethod.NONE) {
      return true
    }
    switch (environment.authMethod) {
      case AuthMethod.AUTH_0: {
        return this.auth0Authentication(state);
      }
      case AuthMethod.KEY_CLOAK: {
        return this.isAccessAllowed(route, state) as Promise<boolean>;
      }
    }
  }

  /**
   * Check if the user is authenticate in both auth0 and netop logics
   * @param state
   */
  async auth0Authentication(state: RouterStateSnapshot): Promise<boolean> {
    const authService = this.injector.get(Auth0Service);
    const isAuthenticate = await authService.isAuthenticated();

    if (!isAuthenticate) {
      return this.blockAndNavigateToLogin(state);
    }

    const user = await this.userService.authenticateUser();
    if (user && isAuthenticate) {
      this.authStorageService.saveUserProfile({
        firstName: user.firstName,
        lastName: user.surname,
        email: user.email,
        username: `${user.firstName} ${user.surname}`
      });
      return true;
    } else {
      return this.blockAndNavigateToLogin(state);
    }
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const isLoggedIn = await this.keycloak.isLoggedIn();

    if (isLoggedIn) {
      const userProfile = await this.keycloak.loadUserProfile();
      this.authStorageService.saveUserProfile({
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
        username: userProfile.username
      });
      // Get the roles required from the route.
      const requiredRoles = route.data.roles;

      // Allow the user to to proceed if no additional roles are required to access the route.
      if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
        return true;
      }

      // Allow the user to proceed if all the required roles are present.
      return requiredRoles.every((role) => this.roles.includes(role));
    } else {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

  }

  /**
   * Return false for auth process and navigate to login page
   * @param state
   * @private
   */
  private async blockAndNavigateToLogin(state: RouterStateSnapshot) {
    this.router.navigate(['auth/login'], {
      queryParams: {returnUrl: state.url}
    });

    return false;
  }
}
