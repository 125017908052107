import { ActionType } from '../actions/action-types';
import { INavigationState, initialNavigationState } from '../state/navigation.state';



export function navigationReducer(state = initialNavigationState, action: any): INavigationState {
  switch (action.type) {
    case ActionType.SetNavigationOpenState:
      return { ...state, isMinimized: action.payload };
    default:
      return state;
  }
}
