import { HealthIndicator } from '../../models/health.model';
import { ReqInfoMocked } from './req-info.mocked';

export function getTenantsActions(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
    const tenantsActions = [
        {
            "id": 5,
            health: 50.02,
            name: "NetOp Lab",
            category: 4,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Operational",
            description: "All attempts to connect to EmployeesRadius fabric failed",
            status: "Open",
            affectedFabrics: 4,
            statusIndicator: HealthIndicator.MOST_SEVERE
        }, {
            "id": 6,
            health: 50.1,
            name: "NetOp Lab",
            category: 4,
            categoryName: "Operational",
            description: "Detected 25 reboots on  Lab02 AP1 in last 2 hours",
            status: "Open",
            affectedFabrics: 2,
            statusIndicator: HealthIndicator.MOST_SEVERE
        },
        {
            "id": 3,
            health: 80.01,
            name: "NetOp Lab",
            category: 4,
            categoryName: "Operational",
            description: "Access Point Yuval's AP is near maximum capacity",
            status: "Open",
            affectedFabrics: 2,
            statusIndicator: HealthIndicator.MOST_SEVERE
        },
        {
            "id": 1,
            health: 90.54,
            name: "NetOp Lab",
            category: 4,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Operational",
            description: "Fabric netop guest is not used for 10 days",
            status: "Open",
            affectedFabrics: 6,
        },
        {
            "id": 2,
            health: 90,
            name: "NetOp Lab",
            category: 4,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Operational",
            description: "Fabric Open Wifi is not used for 3 days",
            status: "Acknowledged",
            affectedFabrics: 4,
        }, {
            "id": 4,
            health: 80,
            name: "NetOp Lab",
            category: 4,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Operational",
            description: "Unusual load detected on Switch1 Lab02, 40% above average",
            status: "Acknowledged",
            affectedFabrics: 6,
        }, {
            "id": 7,
            health: 50,
            name: "NetOp Lab",
            category: 4,
            categoryName: "Operational",
            description: "High temperature alert received on Lab02 AP1",
            status: "Completed",
            affectedFabrics: 0,
        },
        {
            "id": 6,
            health: -1,
            name: "Open Wifi",
            category: 5,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Security",
            description: "Malware download was detected on Fabric Open Wifi by client “Zoei’s Samsung S10",
            status: "Open",
            affectedFabrics: 4,
        },
    ];
    return tenantsActions;
}

export function getTenantsHealth(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
    const health = [
        {
            "id": 251081510,
            "tenantName": "Beatzmi",
            "fabricsHealth": -1.0,
            "devicesHealth": 84.12698364257812,
            "priority": 1,
            "statusIndicator": null,
            "venuesHealth": {
                "total": 21,
                "high": 5,
                "medium": 5
            }
        },
        {
            "id": 296692316,
            "tenantName": "Clalit",
            "fabricsHealth": -1.0,
            "devicesHealth": 89.02462005615234,
            "priority": 1,
            "statusIndicator": null,
            "venuesHealth": {
                "total": 499,
                "high": 129,
                "medium": 129
            }
        },
        {
            "id": 38104,
            "tenantName": "BezeqIntLab",
            "fabricsHealth": -1.0,
            "devicesHealth": 100.0,
            "priority": 1,
            "statusIndicator": null,
            "venuesHealth": {
                "total": 2,
                "high": 0,
                "medium": 0
            }
        },
        {
            "id": 1253027,
            "tenantName": "SimplyNetworks",
            "fabricsHealth": -1.0,
            "devicesHealth": 100.0,
            "priority": 1,
            "statusIndicator": null,
            "venuesHealth": {
                "total": 4,
                "high": 0,
                "medium": 0
            }
        },
        {
            "id": 61022388,
            "tenantName": "ElauwitLab",
            "fabricsHealth": -1.0,
            "devicesHealth": 100.0,
            "priority": 1,
            "statusIndicator": null,
            "venuesHealth": {
                "total": 1,
                "high": 0,
                "medium": 0
            }
        },
        {
            "id": 1,
            "tenantName": "netop",
            "fabricsHealth": -1.0,
            "devicesHealth": 100.0,
            "priority": 1,
            "statusIndicator": null,
            "venuesHealth": {
                "total": 4,
                "high": 2,
                "medium": 2
            }
        },
        {
            "id": 250034903,
            "tenantName": "EasySpace",
            "fabricsHealth": -1.0,
            "devicesHealth": 100.0,
            "priority": 1,
            "statusIndicator": null,
            "venuesHealth": {
                "total": 2,
                "high": 0,
                "medium": 0
            }
        },
        {
            "id": 284469537,
            "tenantName": "Billyhouse",
            "fabricsHealth": -1.0,
            "devicesHealth": 100.0,
            "priority": 1,
            "statusIndicator": null,
            "venuesHealth": {
                "total": 1,
                "high": 0,
                "medium": 0
            }
        },
        {
            "id": 250056573,
            "tenantName": "IPrint",
            "fabricsHealth": -1.0,
            "devicesHealth": 104.16666412353516,
            "priority": 1,
            "statusIndicator": null,
            "venuesHealth": {
                "total": 1,
                "high": 0,
                "medium": 0
            }
        }
    ];
    return health;
}
