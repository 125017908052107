import { EntityType } from '../../models/entity-type.enum';
import { RoleAccess } from '../../models/role-access.model';
import { User } from '../../models/users.model';

export const organizations = [
    { "id": 1, "name": "house4" },
    { "id": 2, "name": "house5" }
];
export const roles = [
    { "id": 1, "name": "Organization Manager" },
    { "id": 4, "name": "Tenant Manager" }
];


export const users: User[] = [
    {
        id: 1,
        email: "igor@netop-cloud.com",
        phoneNumber: "02-34566788",
        firstName: "Igor Org",
        surname: "Iris"
    }
]
export const singleUser = users[0]
export const tenantUsers: User[] = [
    {
        id: 1,
        email: "igor@netop-cloud.com",
        phoneNumber: "02-34566788",
        firstName: "Igor Tenant",
        surname: "Iris"
    }
]


export const defaultAccess: RoleAccess = {
    resourceId: 1,
    roleType: EntityType.TENANT,
    roleId: 1
}