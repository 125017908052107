import { ReqInfoMocked } from './req-info.mocked';
import { Page } from '../../models/page.model';
import { Alert } from '../../models/alerts.model';


export function getAlerts(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
    const alerts: Alert[] = [
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Streaming Stopped",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Rain start",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Sun is out",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "No Electricity",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "No Traffic",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "User Stopped",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Cloud break",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Tsunami of data",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Macacbi lost",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Apple was bankrupted",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Exit",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Unicorn",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Alfa",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Beta",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Gama",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Delta",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Inch",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Meter",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Chaos",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Order",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        },
        {
            alertData: "Black Device - Working",
            alertId: "1",
            alertType: "Genesis",
            deviceMac: "OS",
            deviceName: "Apple watch",
            deviceSerial: "123-JFDJ-34",
            deviceUrl: "8080://",
            id: "6",
            networkId: "4",
            networkName: "Free wifi",
            networkUrl: "192.23.44",
            occurredAt: "1578212469",
            organizationId: "3",
            organizationName: "NetOp",
            organizationUrl: "http",
            sentAt: 1578212469,
            sharedSecret: "Yes",
            version: "2.0"
        }]

    return alerts
}
