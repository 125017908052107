import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { PageSpinnerComponent } from '../components/page-spinner/page-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class PageSpinnerService {
  static modal: MatDialogRef<any>;

  constructor(public dialog: MatDialog, private overlay: Overlay) {}

  show() {
    if (!PageSpinnerService.modal) {
      PageSpinnerService.modal = this.dialog.open(PageSpinnerComponent, {
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        panelClass: 'page-spinner-dialog'
      });
    }
  }

  hide() {
    if (PageSpinnerService.modal) {
      const subscription = PageSpinnerService.modal
        .afterClosed()
        .subscribe(() => {
          PageSpinnerService.modal = undefined;
          subscription.unsubscribe();
        });
      PageSpinnerService.modal.close();
    }
  }
}
