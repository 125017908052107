import {ReqInfoMocked} from './req-info.mocked';
import {
  FabricAdvancedParameters,
  FabricKpiData,
  FabricStatus,
  MisconfigurationCategory
} from '../../models/fabrics.model';

export function getSelectedFabric(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
  let selectedFabric: FabricKpiData;
  singleFabrics.forEach(item => {
    if (item.fabric.id == parseInt(urlParams[2])) {
      selectedFabric = item;
    }
  })
  if (selectedFabric)
    return selectedFabric;
}

export const fabricsSummary = [
  {"id": 1, "name": "fabric 1", "numOfAP": 4, "numOfSwitches": 3, "numOfSDWAN": 2, "numOfFWs": 1},
  {"id": 3, "name": "fabric 3", "numOfAP": 4, "numOfSwitches": 3, "numOfSDWAN": 2, "numOfFWs": 1}
];

export const fabricsMocked: any[] = [
  {"id": 1, "name": "fabric 1", fabricStatus: {status: FabricStatus.COMPLETE, reason: ""}, properties: null},
  {"id": 3, "name": "fabric 3", fabricStatus: {status: FabricStatus.INCOMPLETE, reason: ""}, properties: null},
  {"id": 4, "name": "fabric 4", fabricStatus: {status: FabricStatus.NoWwwConnectivity, reason: ""}, properties: null}
];

export const fabricAdvancedParameters: FabricAdvancedParameters = {
  vlanParameters: [{name: "VLAN1", subnet: "127.0.0.1", id: "1", umd: "1234", password: "1234"}],
  ssidParameters: [{name: "SSID1", externalId: "0976", id: "1", users: "45", ip: "127.0.0.1"}],
}

export const singleFabrics: any[] = [
  {
    "fabric": {
      "id": 6,
      "name": "SSID_0_SANITY",
      "fabricStatus": {
        "status": FabricStatus.COMPLETE,
        "reason": null
      },
      "connectedClients": 0,
      "tenantId": 1,
      "properties": null,
      "ssids": [
        {
          "id": 8,
          "name": "SSID_0_SANITY",
          "description": "encryption:psk, vlan tag: 200",
          "parameters": {
            "splashPageProfile": "None",
            "minBitrate": 24.0,
            "ipAssignmentMode": "Bridge mode",
            "externalId": "0",
            "psk": "12345678",
            "vendorId": "0",
            "lanIsolationEnabled": false,
            "bands": "20",
            "vendorName": "meraki",
            "ssidAdminAccessible": false,
            "wpaEncryptionMode": "WPA1 and WPA2",
            "defaultVlanId": 200,
            "authMode": "psk",
            "perClientBandwidthLimitUp": 0,
            "useVlanTagging": "true",
            "number": 0,
            "apiVersion": "v0",
            "encryptionMode": "wpa",
            "tagsToVlans": {
              "rnd": "100",
              "test": "150"
            },
            "name": "SSID_0_SANITY",
            "networkId": "L_682858293500071313",
            "id": 8,
            "perClientBandwidthLimitDown": 0,
            "status": "true"
          }
        }
      ],
      "vlans": [
        {
          "id": 25,
          "name": "V100_Igor_Tests",
          "description": "subnet:192.168.100.0/24",
          "externalId": null,
          "networkId": null,
          "applianceIp": null,
          "subnet": null,
          "dnsNameservers": null,
          "parameters": {
            "dnsNameservers": "upstream_dns",
            "subnet": "192.168.100.0/24",
            "dhcpHandling": "Run a DHCP server",
            "dhcpOptions": [],
            "externalId": "100",
            "vendorId": "100",
            "dhcpBootOptionsEnabled": false,
            "vendorName": "meraki",
            "fixedIpAssignments": {},
            "dhcpLeaseTime": "1 day",
            "apiVersion": "v0",
            "tagsToVlans": {},
            "name": "V100_Igor_Tests",
            "networkId": "L_682858293500071313",
            "id": 25,
            "applianceIp": "192.168.100.1",
            "reservedIpRanges": [],
            "status": null
          }
        },
        {
          "id": 26,
          "name": "V2000_SANITY_Tests",
          "description": "subnet:20.10.200.0/24",
          "externalId": null,
          "networkId": null,
          "applianceIp": null,
          "subnet": null,
          "dnsNameservers": null,
          "parameters": {
            "dnsNameservers": "upstream_dns",
            "subnet": "20.10.200.0/24",
            "dhcpHandling": "Run a DHCP server",
            "dhcpOptions": [],
            "externalId": "2000",
            "vendorId": "2000",
            "dhcpBootOptionsEnabled": false,
            "vendorName": "meraki",
            "fixedIpAssignments": {},
            "dhcpLeaseTime": "1 day",
            "apiVersion": "v0",
            "tagsToVlans": {},
            "name": "V2000_SANITY_Tests",
            "networkId": "L_682858293500071313",
            "id": 26,
            "applianceIp": "20.10.200.1",
            "reservedIpRanges": [],
            "status": null
          }
        },
        {
          "id": 18,
          "name": "Default2",
          "description": "subnet:192.168.128.0/24",
          "externalId": null,
          "networkId": null,
          "applianceIp": null,
          "subnet": null,
          "dnsNameservers": null,
          "parameters": {
            "dnsNameservers": "upstream_dns",
            "subnet": "192.168.128.0/24",
            "dhcpHandling": "Run a DHCP server",
            "dhcpOptions": [],
            "externalId": "1",
            "vendorId": "1",
            "dhcpBootOptionsEnabled": false,
            "vendorName": "meraki",
            "fixedIpAssignments": {},
            "dhcpLeaseTime": "1 week",
            "apiVersion": "v0",
            "tagsToVlans": {},
            "name": "Default2",
            "networkId": "L_682858293500071313",
            "id": 18,
            "applianceIp": "192.168.128.1",
            "reservedIpRanges": [],
            "status": null
          }
        }
      ]
    },
    "kpis": {
      "latency": 10,
      "users": 2,
      "traffic": 300,
      "loss": 0.1,
      "utilization": 99.9
    },
    "health": "100.0",
    "healthDegradationReasons": []
  },
  {
    fabric: {
      id: 1,
      connectedClients: 234,
      name: "MS-100",
      fabricStatus: {status: FabricStatus.COMPLETE, reason: "No Connections"},
      tenantId: 2,
      properties: null,
      ssids: [],
      vlans: [],
      reason: '',
      category: MisconfigurationCategory.POLICY,
      solution: ''
    },
    health: "45",
    kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45},
    healthDegradationReasons: [
      {percentage: 55, reason: "The device is currently offline"},
      {percentage: 22, reason: "Exceeded %.0fms latency threshold"},
      {percentage: 44, reason: "Exceeded %.2f percents loss threshold"},
      {percentage: 66, reason: "Based on Performance Score value"},
      {percentage: 77, reason: "Critical Temperature level causing device malfunction"},
      {percentage: 88, reason: "Cable Errors detected on critical ports."},
      {percentage: 88, reason: "AP went down for %d times in last %d %s"},
    ]
  },
  {
    fabric: {
      id: 3,
      connectedClients: 234,
      name: "999-ms-999",
      fabricStatus: {status: FabricStatus.COMPLETE, reason: "No Connections"},
      tenantId: 2,
      properties: null,
      ssids: [],
      vlans: [],
      reason: '',
      category: MisconfigurationCategory.POLICY,
      solution: ''
    },
    health: "45",
    kpis: {latency: 50, users: 2000, traffic: 3, loss: 30, utilization: 45},
    healthDegradationReasons: [
      {percentage: 55, reason: "The device is currently offline"},
      {percentage: 22, reason: "Exceeded %.0fms latency threshold"},
      {percentage: 44, reason: "Exceeded %.2f percents loss threshold"},
      {percentage: 66, reason: "Based on Performance Score value"},
      {percentage: 77, reason: "Critical Temperature level causing device malfunction"},
      {percentage: 88, reason: "Cable Errors detected on critical ports."},
      {percentage: 88, reason: "AP went down for %d times in last %d %s"},
    ]
  }]


export function putFabricConfiguration(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
  return {
    "configu ationEntityId": 10,
    "cnfigurationEntityType": "Ssid",
    "properties": {"band": 20}
  }
}
