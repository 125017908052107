import { LayoutModule } from "@angular/cdk/layout";
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule, ErrorHandler } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, DEBUG_MODE } from "@angular/fire/compat/analytics";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { KeycloakAngularModule } from "keycloak-angular";
import { InlineSVGModule } from "ng-inline-svg";
import { DeviceDetectorService } from "ngx-device-detector";
import { environment } from "src/environments/environment.local_stage";
import { EnvironmentType } from "src/environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthMethodGenerator } from "./auth/auth-method-generator";
import { TokenInterceptorService } from "./auth/services/token-interceptor.service";
import { GlobalErrorHandlerService } from "./core/services/global-error-handler.service";
import { HttpSettingInterceptorService } from "./shared/services/http-setting-interceptor.service";
import { InMemoryDataService } from "./shared/services/in-memory/in-memory-data.service";
import { LoggerService } from "./shared/services/logger.service";
import { SharedModule } from "./shared/shared.module";
import { NgrxModule } from "./store/ngrx.module";
import * as LOG_CONFIG from './config/logging-config';
import * as PROD_CONFIG from "./config/logging-config.prod";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    StoreModule.forRoot({
      // navigationDetails: navigationReducer,
      // "current-entity": fromCurrentEntity.reducer,
      // "hierarchy": fromHierarchy.reducer
    }),
    NgrxModule,
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    environment.disableInMemory ?
      [] : HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
        apiBase: environment.apiBaseUrl,
        passThruUnknownUrl: true
      }),
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    !environment.disableFirebase ? [
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireAnalyticsModule,
      AngularFireAuthModule,
      AngularFireAuthModule,
      AngularFireDatabaseModule,
    ] : [],
    KeycloakAngularModule,
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: Window,
      useValue: window
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSettingInterceptorService,
      multi: true
    },
    {
      provide: LoggerService,
      useFactory: (deviceService: DeviceDetectorService) => {
        const loggerConfig = EnvironmentType.isLoggingAllowed(environment.environmentType) ? LOG_CONFIG.logConfig : PROD_CONFIG.logConfig;
        return new LoggerService(loggerConfig, deviceService);
      },
      deps: [DeviceDetectorService]
    },
    // firebase debug mode
    // TODO: this should be set only in none production mode
    { provide: DEBUG_MODE, useValue: !environment.production },
    // firebasd emulator - enable only on dev
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.firebase && environment.firebase.localInvocation ? 'http://localhost:5000' : ''
    },
    // skip authentication in dev mode
    AuthMethodGenerator.getAuthMethodProvider(environment.authMethod),
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
