import { MetaReducer, StoreModule } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { NgrxRouterStoreModule } from './router/reducers/ngrx-router.module';
import { RouterEffects } from './router/effects/router.effects';
import { HierarchyEffects } from './effects/hierarchy.effects';
import * as fromCurrentEntity from './reducers/current-entities.reducer';
import * as fromHierarchy from './reducers/hierarchy.reducer';
import { navigationReducer } from './reducers/navigation.reducer';

export const metaReducers: MetaReducer<{}>[] =
  !environment.production ? [storeFreeze] : [];

@NgModule({
  imports: [
    StoreModule.forFeature(fromCurrentEntity.currentEntityFeatureKey, fromCurrentEntity.reducer),
    StoreModule.forFeature(fromHierarchy.hierarchyFeatureKey, fromHierarchy.reducer),
    StoreModule.forFeature("navigationDetails", navigationReducer),
    // StoreModule.forRoot({} as ActionReducerMap<IAppState>, { metaReducers }),
    EffectsModule.forRoot([RouterEffects, HierarchyEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    NgrxRouterStoreModule,
  ]
})
export class NgrxModule {
}








