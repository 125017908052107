import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, ROUTER_NAVIGATED, RouterNavigationAction, ROUTER_REQUEST, ROUTER_ERROR, ROUTER_CANCEL } from '@ngrx/router-store';
import { tap, map, mapTo, withLatestFrom, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Logger, LoggerService } from 'src/app/shared/services/logger.service';
import { currentEntityNavigated, currentEntityNavigationStarted, currentEntityNavigationError, currentEntityNavigationCancel } from '../../actions/entities.action-types';
import { EntityType } from 'src/app/shared/models/entity-type.enum';
import { selectEntityType, selectEntityId } from '../reducers/router.selector';
import { selectPrevEntity } from '../../selectors/entities.selector';
import { Entity } from 'src/app/shared/models/entity.model';


//
@Injectable()
export class RouterEffects {
  logger: Logger;
  constructor(private actions$: Actions,
    private store: Store<any>,
    private loggerFactory: LoggerService) {
    this.logger = this.loggerFactory.getLogger("RouterEffects")
  }
  navigationStart$ = createEffect(() => this.actions$.pipe(
    ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
    tap(() => {
      this.logger.debug('effect router *navigation* called')
    }),
    withLatestFrom(this.store.select(selectEntityType).pipe(
      tap((entityType) => {
        this.logger.debug(`the entity type selected on *nav start* ${entityType}`)
      }),
      filter((entityType) => !!<any>entityType)
    ), this.store.select(selectEntityId).pipe(
      tap((entityId) => {
        this.logger.debug(`the entity id selected on *nav start* ${entityId}`)
      }),
      filter((entityId) => !!entityId)
    )),
    map(([action, entityType, entityId]) => {
      return currentEntityNavigationStarted({ entityId, entityType })
    })
  ));
  navigationEnd$ = createEffect(() => this.actions$.pipe(
    ofType<RouterNavigationAction>(ROUTER_NAVIGATED),
    tap(() => {
      this.logger.debug('effect router *navigated* called')
    }),
    withLatestFrom(this.store.select(selectEntityType).pipe(
      tap((entityType) => {
        this.logger.debug(`the entity type selected on *nav end* ${entityType}`)
      }),
      filter((entityType) => !!<any>entityType)
    ), this.store.select(selectEntityId).pipe(
      tap((entityId) => {
        this.logger.debug(`the entity id selected on *nav end* ${entityId}`)
      }),
      filter((entityId) => !!entityId)
    )),
    map(([action, entityType, entityId]) => {
      //return routerNavigated()
      this.logger.debug(`returning currentEntityNavigated on *nav end* ${entityType} ${entityId}`)
      return currentEntityNavigated({ entityId, entityType })
    })
  ));
  navigationError$ = createEffect(() => this.actions$.pipe(
    ofType<RouterNavigationAction>(ROUTER_ERROR),
    tap(() => {
      this.logger.debug('effect router *error* called')
    }),
    map(() => {
      return currentEntityNavigationError()
    })
  ));
  navigationCancel$ = createEffect(() => this.actions$.pipe(
    ofType<RouterNavigationAction>(ROUTER_CANCEL),
    tap(() => {
      this.logger.debug('effect router *error* called')
    }),// selectPrevEntity
    withLatestFrom(this.store.select(selectPrevEntity).pipe(
      tap((entity: Entity) => {
        this.logger.debug("the entity *nav canceled* %o", entity)
      })
    )),
    map(([action, entity]) => {
      return currentEntityNavigationCancel({ entity })
    })
  ));
}
