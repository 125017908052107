import { ReqInfoMocked } from './req-info.mocked';
import { EntityType } from '../../models/entity-type.enum';

export function getEntityTypeFromUrl(reqInfo: ReqInfoMocked) {
    var pageURL = window.location.pathname;
    let urlParts = pageURL.split('/')
    let availableTypes = {}
    for (let type in EntityType) {
        availableTypes[type.toLowerCase()] = type
    }
    const entityType = urlParts.slice(0).reverse().find(step => !!availableTypes[step.toLowerCase()])
    return EntityType[availableTypes[entityType]]
}
