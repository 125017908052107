import {AnomalyCategory, AnomalySummaryByCategory, DeviceAnomalies, DeviceIssuesAnomaly} from '../../models/anomalies.model';
import {Page} from '../../models/page.model';
import {ReqInfoMocked} from './req-info.mocked';
import {EntityType} from '../../models/entity-type.enum';
import {ActionCategory, ActionStatus, ResolutionType} from '../../models/actions.model';
import {HealthIndicator} from '../../models/health.model';
import {DeviceState} from '../../models/devices.model';


export const anomaliesMocked: AnomalySummaryByCategory[] = [
  {
    anomalyCategory: AnomalyCategory.Devices,
    total: 67,
    active: 12
  },
  {
    anomalyCategory: AnomalyCategory.PacketLoss,
    total: 767,
    active: 12
  },
  {
    anomalyCategory: AnomalyCategory.Latency,
    total: 1,
    active: 12
  }
  // {
  //     anomalyCategory: AnomalyCategory.VPN_ISSUES,
  //     total: 89
  // },
  // {
  //     anomalyCategory: AnomalyCategory.VPN_UNSUALL_TRAFFIC,
  //     total: 567
  // },
  // {
  //     anomalyCategory: AnomalyCategory.WAN_UNSUALL_TRAFFIC,
  //     total: 666
  // },
  // {
  //     anomalyCategory: AnomalyCategory.CONNECTION_FAILURES,
  //     total: 5
  // }
]

export function getDeviceIssuesList(reqInfo: ReqInfoMocked, urlParams: Array<string>): DeviceIssuesAnomaly[] {
  return [
    {
      tenantName: "netop 1",
      venueName: "al-kasom 1",
      action: {
        id: 3264,
        tenantId: 1,
        venueId: 19,
        updatedAt: "1579726957361",
        entityType: EntityType.DEVICE,
        entityId: 56,
        name: "Yuval's AP",
        status: ActionStatus.Open,
        category: ActionCategory.Operational,
        type: DeviceAnomalies.DeviceStatusDownForLongTime,
        severity: 2,
        resolutionType: ResolutionType.Manual,
        description: "reboots occured on access point Yuval's AP serial:Q2PD-7VW3-RRZU during last .↵",
        suggestedOperation: "Make sure Power source is stable. if power is transfered over Ethernet cable, consider replacing the cable with new one.",
        nextPossibleStates: [ActionStatus.Ignore, ActionStatus.Ack, ActionStatus.Complete],
        categoryName: "Operational",
        affects: 22,
        statusIndicator: HealthIndicator.MOST_SEVERE,
        anomalyCategory: AnomalyCategory.Traffic,
        summary: [],
        possibleRootCauses: [],
        manualSolutions: [],
        additionalData: {
          name: 'Device',
          downSince: 'Yesterday',
          serial:' 25-54-98-87'
        }
      },
      deviceStatusHistory: [
        {
          id: 1,
          deviceId: 22,
          startTime: 1598166000000,
          endTime: 1598173200000,
          currentStatus: DeviceState.Alerting,
          createdAt: 1578212469,
          updatedAt: 1578212469,
          integrationPointId: 4,
          tenantId: 1,
          time: 1578212469,

        },
        {
          id: 5,
          deviceId: 22,
          startTime: 1598166000000,
          endTime: 1598173200000,
          currentStatus: DeviceState.Online,
          createdAt: 1578212469,
          updatedAt: 1578212469,
          integrationPointId: 4,
          tenantId: 1,
          time: 1578212469,

        },
        {
          id: 8,
          deviceId: 22,
          startTime: 1598166000000,
          endTime: 1598173200000,
          currentStatus: DeviceState.Unknown,
          createdAt: 1578212469,
          updatedAt: 1578212469,
          integrationPointId: 4,
          tenantId: 1,
          time: 1578212469,

        },
        {
          id: 1,
          deviceId: 22,
          startTime: 1598166000000,
          endTime: 1598173200000,
          currentStatus: DeviceState.Offline,
          createdAt: 1578212469,
          updatedAt: 1578212469,
          integrationPointId: 4,
          tenantId: 1,
          time: 1578212469,

        },
        {
          id: 1,
          deviceId: 22,
          startTime: 1598166000000,
          endTime: 1598173200000,
          currentStatus: DeviceState.Alerting,
          createdAt: 1578212469,
          updatedAt: 1578212469,
          integrationPointId: 4,
          tenantId: 1,
          time: 1578212469,

        },
      ]
    },
    {
      tenantName: "netop 2",
      venueName: "al-kasom 2",
      action: {
        id: 3266,
        tenantId: 1,
        venueId: 19,
        updatedAt: "1579726957361", entityType: EntityType.DEVICE,
        entityId: 56,
        name: "Yuval's AP",
        status: ActionStatus.Open,
        category: ActionCategory.Operational,
        type: DeviceAnomalies.DeviceStatusNeverUp,
        severity: 2,
        resolutionType: ResolutionType.Manual,
        description: " reboots occured on access point Yuval's AP serial:Q2PD-7VW3-RRZU during last .↵",
        suggestedOperation: "Make sure Power source is stable. if power is transfered over Ethernet cable, consider replacing the cable with new one.",
        nextPossibleStates: [ActionStatus.Ignore, ActionStatus.Ack, ActionStatus.Complete],
        categoryName: "Operational",
        affects: 22,
        statusIndicator: HealthIndicator.MOST_SEVERE,
        anomalyCategory: AnomalyCategory.Traffic,
        summary: [],
        possibleRootCauses: [],
        manualSolutions: [],
        additionalData: {
          name: 'Device',
          downSince: 'Yesterday',
          serial:' 25-54-98-87'
        }
      },
      deviceStatusHistory: [

        {
          id: 2,
          deviceId: 455,
          startTime: 1598176800000,
          endTime: 1598180400000,
          currentStatus: DeviceState.Unknown,
          createdAt: 1578212469,
          updatedAt: 1578212469,
          integrationPointId: 4,
          tenantId: 1,
          time: 1578212469,
        }
      ]
    }
  ]
}


export const deviceIssues: Page<DeviceIssuesAnomaly> = {
  total: 2,
  data: [
    //         {
    //             action: {

    //                 id: 3264,
    //                 tenantId: 1,
    //                 venueId: 19,
    //                 updatedAt: "1579726957361",
    //                 entityType: EntityType.DEVICE,
    //                 entityId: 56,
    //                 name: "Yuval's AP",
    //                 status: ActionStatus.Open,
    //                 category: ActionCategory.Operational,
    //                 type: { desc: "APReboots", value: "APReboots" },
    //                 severity: 2,
    //                 resolutionType: ResolutionType.Manual,
    //                 description: "reboots occured on access point Yuval's AP serial:Q2PD-7VW3-RRZU during last .↵",
    //                 suggestedOperation: "Make sure Power source is stable. if power is transfered over Ethernet cable, consider replacing the cable with new one.",
    //                 nextPossibleSteps: [ActionStatus.Ignore, ActionStatus.Ack, ActionStatus.Complete],
    //                 categoryName: "Operational",
    //                 affects: 22,
    //                 statusIndicator: HealthIndicator.MOST_SEVERE
    //             },
    //             deviceStatusHistory: [
    //                 {
    //                     deviceId: 22,
    //                     startTime: "1578212469",
    //                     endTime: "1578212469",
    //                     currentStatus: DeviceState.Alerting
    //                 }
    //             ]
    //         },
    //         {
    //             action: {
    //                 id: 3266,
    //                 tenantId: 1,
    //                 venueId: 19,
    //                 updatedAt: "1579726957361", entityType: EntityType.DEVICE,
    //                 entityId: 56,
    //                 name: "Yuval's AP",
    //                 status: ActionStatus.Open,
    //                 category: ActionCategory.Operational,
    //                 type: { desc: "APReboots", value: "APReboots" },
    //                 severity: 2,
    //                 resolutionType: ResolutionType.Manual,
    //                 description: " reboots occured on access point Yuval's AP serial:Q2PD-7VW3-RRZU during last .↵",
    //                 suggestedOperation: "Make sure Power source is stable. if power is transfered over Ethernet cable, consider replacing the cable with new one.",
    //                 nextPossibleSteps: [ActionStatus.Ignore, ActionStatus.Ack, ActionStatus.Complete],
    //                 categoryName: "Operational",
    //                 affects: 22,
    //                 statusIndicator: HealthIndicator.MOST_SEVERE
    //             },
    //             deviceStatusHistory: [
    //                 {
    //                     deviceId: 455,
    //                     startTime: "1578212469",
    //                     endTime: "1578212469",
    //                     currentStatus: DeviceState.Unknown
    //                 }
  ]
}

