import {Injectable, Injector} from '@angular/core';
import {EnvironmentType} from 'src/environments/environment.model';
import {environment} from '../../../environments/environment';
import {Logger, LoggerService} from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class UrlResolverService {
  private logger: Logger; components; columnDefs;

  constructor(private loggerFactory: LoggerService) {
    this.logger = this.loggerFactory.getLogger("UrlResolverService");

  }

  getBaseApiUrl(isLogout: boolean = false) {
    if (EnvironmentType.isOnPrem(environment.environmentType)) {
      return `${window.location.origin}/api/1.0`;
    }
    if (isLogout) {
      return environment.apiBaseUrl.replace('/1.0', '');
    }
    return environment.apiBaseUrl;
  }

  getKeycloakUrl(keyCloakUrl: string) {
    return keyCloakUrl ?
      keyCloakUrl :
      `${window.location.origin}/auth`;
  }
}
