import { LegacySeverity } from "../../models/severity.model";
import { ReqInfoMocked } from './req-info.mocked';
import { environment } from 'src/environments/environment';
import { getEntityTypeFromUrl } from './in-memory-utils';
import { EntityType } from '../../models/entity-type.enum';
import { getTenantsActions } from './tenants-health-actions.mocked';
import { getVenuesActions } from './venues-health-actions.mocked';
import { getFabricsActions } from './fabrics-health-actions.mocked';
import { getDevicesActions } from './devices-health-actions.mocked';

export function getIssuesSeverity(reqInfo: ReqInfoMocked) {
    return [{
        category: LegacySeverity.High,
        value: 2478,
        label: "Hight risk",
    }, {
        category: LegacySeverity.Medium,
        value: 5267,
        label: "Medium risk",
    }, {
        category: LegacySeverity.Minor,
        value: 734,
        label: "Minor issues",
    }]
}

export function getIssuesCategory(reqInfo: ReqInfoMocked) {
    let entityType = getEntityTypeFromUrl(reqInfo)
    //debugger
    // call the mock actions methods first to get the data and then count/group them for the donut data
    let actionsMethods = {
        [EntityType.TENANT]: getVenuesActions,
        [EntityType.ORGANIZATION]: getTenantsActions,
        [EntityType.VENUE]: getVenuesActions,
        [EntityType.FABRIC]: getFabricsActions,
        [EntityType.DEVICE]: getDevicesActions
    }
    let func = actionsMethods[entityType]
    let actionsData: Array<{ categoryName: string, category: number }> = func.call(this, reqInfo)
    // categoryName => label,category,value = count
    //debugger
    let accamulatedActions = actionsData.reduce((acc, cur) => {
        let category: number = cur["category"]
        if (acc[category]) {
            acc[category]["value"]++;
            return acc;
        }
        acc[category] = {
            category,
            value: 1,
            label: cur["categoryName"]
        }
        return acc
    }, {})
    return Object.values(accamulatedActions)
}

export function getIssues(reqInfo: ReqInfoMocked) {
    // console.log('getIssues called')
    let entityType: ('Severity' | 'Category') = (reqInfo as any).query.get("groupBy")[0];
    let mocks = {
        Severity: getIssuesSeverity,
        Category: getIssuesCategory
    }
    let func = mocks[entityType];
    return func.call(this, reqInfo)
}
