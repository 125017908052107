import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Auth0Service } from '../../shared/services/auth0.service';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentType } from "../../../environments/environment.model";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private authService: Auth0Service) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (environment.disabledAuth || EnvironmentType.isOnPrem(environment.environmentType) ||
      request.url.indexOf('registration/') != -1 ||
      request.url.indexOf('assets/i18n') != -1) {
      /* console.log('registration request - emitting addition of bearer token')
         Do not try to add a bearer token if we are inside an auth flow. */
      return next.handle(request);
    }

    return from(this.authService.getToken()).pipe(
      mergeMap(token => {
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        }
        return next.handle(request);
      })
    );
  }
}
