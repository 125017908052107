import {Auth0Service} from './auth0.service'
import {of, Observable} from 'rxjs';
import {Auth0Profile} from "../../auth/IBaseAuthService";

export class NoAuthService extends Auth0Service {
  get profile$(): Observable<Auth0Profile> {
    return of({
      email: "admin@netop.cloud",
      email_verified: true,
      family_name: "admin",
      given_name: "admin",
      locale: "en",
      name: "admin",
      nickname: "admin",
      picture: "https://lh3.googleusercontent.com/a/AATXAJx8dq-aJAd1cKXEu3SWhqIYCdmwN-1ZVx8EGMR7=s96-c",
      sub: "google-oauth2|101212490629050455033",
      updated_at: "2021-09-09T07:12:41.525Z"
    })
  }

}
