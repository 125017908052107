import {Subscription} from 'rxjs';
import {AfterContentInit, Component, Injector} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {IAppState} from './store/state/app.state';
import * as entitiesGlobalStore from 'src/app/store/selectors/entities.selector';
import {VersionCheckService} from './shared/services/version-check.service';
import {CustomIconService} from './shared/services/custom-icon.service';
import {Logger, LoggerService} from './shared/services/logger.service';
import {AnalyticsService} from './shared/services/analytics.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from "chartjs-plugin-annotation";
import {environment} from "../environments/environment";
import {Chart, DoughnutController, registerables} from "chart.js";
import {FirebaseAuthService} from "./shared/services/client-storage-services/firebase-services/firebase-auth.service";
import {KeycloakProfile} from "keycloak-js";
import Hotjar from '@hotjar/browser';
import { AuthStorageService } from './auth/auth-storage.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements AfterContentInit {
  organizationsDetails = this.appStore.pipe(select(entitiesGlobalStore.selectCurrentOrg));
  routerSubscr: Subscription;
  reloadSubscr: Subscription;
  private analytics: AnalyticsService;
  private firebaseAuth: FirebaseAuthService;
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  private logger: Logger; components; columnDefs;

  constructor(
    private translate: TranslateService,
    private appStore: Store<IAppState>,
    private customIconService: CustomIconService,
    private versionCheckService: VersionCheckService,
    private loggerFactory: LoggerService,
    private firebaseAuthInjector: Injector,
    private authStorageService: AuthStorageService,
    private window: Window
  ) {
    this.logger = this.loggerFactory.getLogger("AppComponent");

    this.initializeTranslate();
    this.customIconService.init();
    this.versionCheckService.initVersionCheck();
    Chart.unregister({...zoomPlugin});
    Chart.unregister({...ChartDataLabels});
    Chart.unregister({...annotationPlugin});
    Chart.register(...registerables);
    Chart.register(DoughnutController);
    if (!environment.disableFirebase) {
      this.firebaseAuth = this.firebaseAuthInjector.get(FirebaseAuthService);
    }
  }

  ngOnInit() {
    this.versionCheckService.versionHash$.subscribe(() => window.location.reload());
  }

  ngAfterContentInit(): void {
    //Hotjar Attributes defined here.
    this.authStorageService.notifyUserProfileObservable$.subscribe(user => {
      if (user) {
        const userId = user.email || null,
          userNameParts = userId.split('@'),
          username = userNameParts[0],
          company = userNameParts[1],
          window = this.window as any;

          window.hj('identify', userId, {
            'company': company,
            'username': username
          });
      }
    });
}

  ngOnDestroy() {
    // this.routerSubscr.unsubscribe()
    if (this.reloadSubscr) {
      this.reloadSubscr.unsubscribe()
    }
    // Call destroy to give those service chance to unsubscribe from the auth service.
    if (!environment.disableFirebase) {
      this.firebaseAuth.destroy();
    }
  }

  private initializeTranslate() {
    // default and when a translation isn't found language to use
    this.translate.setDefaultLang('en');
  }
}

