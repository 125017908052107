import { HealthIndicator } from '../../models/health.model';
import { ReqInfoMocked } from './req-info.mocked';
import { VenuesHealth } from '../../models/venues.model';
import { EntitySyncStatus } from '../../models/sync-status.model';

export function getVenuesActions(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
    const venuesActions = [
        {
            "id": 5,
            health: 50,
            name: "Lab02",
            category: 4,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Operational",
            description: "All attempts to connect to EmployeesRadius fabric failed",
            status: "Open",
            affectedFabrics: 4,
            statusIndicator: HealthIndicator.MOST_SEVERE
        }, {
            "id": 6,
            health: 50,
            name: "Lab02",
            category: 4,
            categoryName: "Operational",
            description: "Detected 25 reboots on  Lab02 AP1 in last 2 hours",
            status: "Open",
            affectedFabrics: 2,
            statusIndicator: HealthIndicator.MOST_SEVERE
        },
        {
            "id": 3,
            health: 80,
            name: "Lab02",
            category: 4,
            categoryName: "Operational",
            description: "Access Point Yuval's AP is near maximum capacity",
            status: "Open",
            affectedFabrics: 2,
            statusIndicator: HealthIndicator.MOST_SEVERE
        },
        {
            "id": 1,
            health: 90,
            name: "Lab02",
            category: 4,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Operational",
            description: "Fabric netop guest is not used for 10 days",
            status: "Open",
            affectedFabrics: 6,
        },
        {
            "id": 2,
            health: 90,
            name: "Lab02",
            category: 4,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Operational",
            description: "Fabric Open Wifi is not used for 3 days",
            status: "Acknowledged",
            affectedFabrics: 4,
        }, {
            "id": 4,
            health: 80,
            name: "Lab02",
            category: 4,
            // category: 1,
            //categoryName: "Performance",
            categoryName: "Operational",
            description: "Unusual load detected on Switch1 Lab02, 40% above average",
            status: "Acknowledged",
            affectedFabrics: 6,
        }, {
            "id": 7,
            health: 50,
            name: "Lab02",
            category: 4,
            categoryName: "Operational",
            description: "High temperature alert received on Lab02 AP1",
            status: "Completed",
            affectedFabrics: 0,
        },
    ];
    return venuesActions;

}
export function getVenuesHealth(reqInfo: ReqInfoMocked, urlParams: Array<string>): any[] {
    // const health = [
    //     {
    //         "id": 24652,
    //         "venueName": "DP_ILANA_SHOLMAN_03-7427333",
    //         "fabricsHealth": -1.0,
    //         "devicesHealth": 0.0,
    //         "statusIndicator": null,
    //         "address": "משה שרת 39, חולון",
    //         "lat": 32.00548,
    //         "long": 34.79208,
    //         syncStatus: EntitySyncStatus.SUCCESS,
    //         "numFabrics": 0,
    //         "numDevices": 3
    //     },
    //     {
    //         "id": 342425,
    //         "venueName": "JVP",
    //         "fabricsHealth": -1.0,
    //         "devicesHealth": 0.0,
    //         "statusIndicator": null,
    //         "address": "משה שרת 39, חולון",
    //         "lat": 32.00548,
    //         "long": 34.79208,
    //         syncStatus: EntitySyncStatus.SUCCESS,
    //         "numFabrics": 0,
    //         "numDevices": 3
    //     }
    // ]
    // for (let i = 0; i < health.length; i++) {
    //     health[i].venueName = health[i].venueName + "rowIndex" + i;
    // }


    const health = [
        {
            id: 1,
            venueName: "Venue 1",
            fabricsHealth: 90,
            devicesHealth: 90,
            address: "Derech Chevron 24",
            numFabrics: 3,
            numDevices: 20,
            syncStatus: EntitySyncStatus.SUCCESS,

        },
        {
            id: 1,
            venueName: "Venue 2",
            fabricsHealth: 90,
            devicesHealth: 80,
            address: "Derech Chevron 21",
            numFabrics: 20,
            numDevices: 20,
            syncStatus: EntitySyncStatus.SUCCESS,
        },
        {
            id: 2,
            venueName: "Venue 3",
            fabricsHealth: 10,
            devicesHealth: 50,
            statusIndicator: HealthIndicator.MOST_SEVERE,
            address: "Derech Chevron 22",
            numFabrics: 40,
            numDevices: 20,
            syncStatus: EntitySyncStatus.SUCCESS,
        },
        {
            id: 3,
            venueName: "Venue 4",
            fabricsHealth: 30,
            devicesHealth: 40,
            address: "Derech Chevron 23",
            numFabrics: 5,
            numDevices: 20,
            syncStatus: EntitySyncStatus.SUCCESS,
        },
        {
            id: 4,
            venueName: "Venue 1",
            fabricsHealth: 40,
            devicesHealth: 30,
            address: "Derech Chevron 20",
            numFabrics: 10,
            numDevices: 20,
            syncStatus: EntitySyncStatus.SUCCESS,
        },
        {
            id: 5,
            venueName: "Venue 5",
            fabricsHealth: 50,
            devicesHealth: 10,
            statusIndicator: HealthIndicator.MOST_SEVERE,
            address: "Derech Chevron 10",
            numFabrics: 7,
            numDevices: 20,
            syncStatus: EntitySyncStatus.SUCCESS,
        },
        {
            id: 6,
            venueName: "Venue 6",
            fabricsHealth: 60,
            devicesHealth: 4,
            statusIndicator: HealthIndicator.MOST_SEVERE,
            address: "Cahn Unes 12",
            numFabrics: 8,
            numDevices: 20
        },
    ];
    return health;
}
