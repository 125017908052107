import {AuthMethod} from "../../environments/environment.model";
import {Auth0Service} from "../shared/services/auth0.service";
import {APP_INITIALIZER, Injector} from "@angular/core";
import {initializeKeycloak} from "./key-cloack/init/keycloak-init.factory";
import {KeycloakService} from "keycloak-angular";
import {ConfigInitService} from "./key-cloack/init/config-init.service";
import {NoAuthService} from "../shared/services/noauth.service";
import {environment} from "../../environments/environment";
import {IBaseAuthService} from "./IBaseAuthService";
import {UrlResolverService} from "../shared/services/url-resolver-service";

export class AuthMethodGenerator {
  public static getAuthMethodProvider(autMethod: AuthMethod) {
    switch (autMethod) {
      case AuthMethod.AUTH_0:
        return {
          provide: Auth0Service,
          useClass: Auth0Service
        };
      case AuthMethod.KEY_CLOAK:
        return {
          provide: APP_INITIALIZER,
          useFactory: initializeKeycloak,
          multi: true,
          deps: [KeycloakService, ConfigInitService, UrlResolverService],
        };
      case AuthMethod.NONE:
        return {
          provide: Auth0Service,
          useClass: NoAuthService
        }
      default:
        return {
          provide: Auth0Service,
          useClass: NoAuthService
        }
    }
  }

  public static getAuthService(injector: Injector): IBaseAuthService {
    switch (environment.authMethod) {
      case AuthMethod.KEY_CLOAK:
        return injector.get(KeycloakService);
      case AuthMethod.AUTH_0:
        return injector.get(Auth0Service);
    }
  }
}
