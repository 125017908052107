export const LOSS_LATENCY_REPORTS = [
    {
        "date": "2020-10-29",
        "total": 1065,
        "thresholds": {
            "INFO": 531,
            "CRITICAL": 534
        }
    },
    {
        "date": "2020-10-30",
        "total": 1082,
        "thresholds": {
            "INFO": 548,
            "CRITICAL": 534
        }
    },
    {
        "date": "2020-10-31",
        "total": 1073,
        "thresholds": {
            "INFO": 535,
            "CRITICAL": 538
        }
    },
    {
        "date": "2020-11-01",
        "total": 1081,
        "thresholds": {
            "INFO": 543,
            "CRITICAL": 538
        }
    },
    {
        "date": "2020-11-02",
        "total": 1063,
        "thresholds": {
            "INFO": 535,
            "CRITICAL": 528
        }
    },
    {
        "date": "2020-11-03",
        "total": 1028,
        "thresholds": {
            "INFO": 522,
            "CRITICAL": 506
        }
    },
    {
        "date": "2020-11-04",
        "total": 1036,
        "thresholds": {
            "INFO": 526,
            "CRITICAL": 510
        }
    },
    {
        "date": "2020-11-05",
        "total": 1118,
        "thresholds": {
            "INFO": 558,
            "CRITICAL": 560
        }
    },
    {
        "date": "2020-11-06",
        "total": 1041,
        "thresholds": {
            "INFO": 517,
            "CRITICAL": 524
        }
    },
    {
        "date": "2020-11-07",
        "total": 1033,
        "thresholds": {
            "INFO": 507,
            "CRITICAL": 526
        }
    },
    {
        "date": "2020-11-08",
        "total": 1034,
        "thresholds": {
            "INFO": 516,
            "CRITICAL": 518
        }
    },
    {
        "date": "2020-11-09",
        "total": 1077,
        "thresholds": {
            "INFO": 539,
            "CRITICAL": 538
        }
    },
    {
        "date": "2020-11-10",
        "total": 1090,
        "thresholds": {
            "INFO": 552,
            "CRITICAL": 538
        }
    },
    {
        "date": "2020-11-11",
        "total": 1062,
        "thresholds": {
            "INFO": 530,
            "CRITICAL": 532
        }
    },
    {
        "date": "2020-11-12",
        "total": 1073,
        "thresholds": {
            "INFO": 521,
            "CRITICAL": 552
        }
    },
    {
        "date": "2020-11-13",
        "total": 1069,
        "thresholds": {
            "INFO": 537,
            "CRITICAL": 532
        }
    },
    {
        "date": "2020-11-14",
        "total": 1086,
        "thresholds": {
            "INFO": 538,
            "CRITICAL": 548
        }
    },
    {
        "date": "2020-11-15",
        "total": 1078,
        "thresholds": {
            "INFO": 534,
            "CRITICAL": 544
        }
    },
    {
        "date": "2020-11-16",
        "total": 1087,
        "thresholds": {
            "INFO": 535,
            "CRITICAL": 552
        }
    },
    {
        "date": "2020-11-17",
        "total": 1111,
        "thresholds": {
            "INFO": 549,
            "CRITICAL": 562
        }
    },
    {
        "date": "2020-11-18",
        "total": 1081,
        "thresholds": {
            "INFO": 535,
            "CRITICAL": 546
        }
    },
    {
        "date": "2020-11-19",
        "total": 1038,
        "thresholds": {
            "INFO": 522,
            "CRITICAL": 516
        }
    },
    {
        "date": "2020-11-20",
        "total": 1052,
        "thresholds": {
            "INFO": 524,
            "CRITICAL": 528
        }
    },
    {
        "date": "2020-11-21",
        "total": 1053,
        "thresholds": {
            "INFO": 515,
            "CRITICAL": 538
        }
    },
    {
        "date": "2020-11-22",
        "total": 1107,
        "thresholds": {
            "INFO": 547,
            "CRITICAL": 560
        }
    },
    {
        "date": "2020-11-23",
        "total": 1036,
        "thresholds": {
            "INFO": 518,
            "CRITICAL": 518
        }
    },
    {
        "date": "2020-11-24",
        "total": 1074,
        "thresholds": {
            "INFO": 530,
            "CRITICAL": 544
        }
    },
    {
        "date": "2020-11-25",
        "total": 1061,
        "thresholds": {
            "INFO": 527,
            "CRITICAL": 534
        }
    },
    {
        "date": "2020-11-26",
        "total": 1035,
        "thresholds": {
            "INFO": 521,
            "CRITICAL": 514
        }
    },
    {
        "date": "2020-11-27",
        "total": 1083,
        "thresholds": {
            "INFO": 535,
            "CRITICAL": 548
        }
    },
    {
        "date": "2020-11-28",
        "total": null,
        "thresholds": null
    }
]
export const TOP_REPORTS_DEVICE_DATA = {
    "value": 7624950,
    "data": [
        {
            "client": {
                "id": null,
                "kpiName": null,
                "organizationId": null,
                "networkId": null,
                "venueName": null,
                "venueId": null,
                "tenantId": null,
                "device": null,
                "time": null,
                "ext_id": null,
                "mac": "e2:49:bc:e8:2d:b4",
                "description": null,
                "ip": null,
                "ip6": null,
                "user": null,
                "firstSeen": null,
                "lastSeen": null,
                "manufacturer": null,
                "os": "Apple iPhone",
                "recentDeviceSerial": null,
                "recentDeviceName": null,
                "recentDeviceMac": null,
                "ssid": null,
                "vlan": null,
                "switchport": null,
                "sent": null,
                "recv": null,
                "status": null,
                "ssidId": null,
                "vlanId": null,
                "deviceId": null,
                "integrationPointId": null
            },
            "traffic": 2836738
        },
        {
            "client": {
                "id": null,
                "kpiName": null,
                "organizationId": null,
                "networkId": null,
                "venueName": null,
                "venueId": null,
                "tenantId": null,
                "device": null,
                "time": null,
                "ext_id": null,
                "mac": "d6:7c:73:18:3a:5c",
                "description": "07cf4179-9a22-496f-96fd-7fa1ebb44886",
                "ip": null,
                "ip6": null,
                "user": null,
                "firstSeen": null,
                "lastSeen": null,
                "manufacturer": null,
                "os": "Apple iPhone",
                "recentDeviceSerial": null,
                "recentDeviceName": null,
                "recentDeviceMac": null,
                "ssid": null,
                "vlan": null,
                "switchport": null,
                "sent": null,
                "recv": null,
                "status": null,
                "ssidId": null,
                "vlanId": null,
                "deviceId": null,
                "integrationPointId": null
            },
            "traffic": 1537807
        },
        {
            "client": {
                "id": null,
                "kpiName": null,
                "organizationId": null,
                "networkId": null,
                "venueName": null,
                "venueId": null,
                "tenantId": null,
                "device": null,
                "time": null,
                "ext_id": null,
                "mac": "b2:1c:e8:1d:f6:d0",
                "description": null,
                "ip": null,
                "ip6": null,
                "user": null,
                "firstSeen": null,
                "lastSeen": null,
                "manufacturer": null,
                "os": "Apple iPhone",
                "recentDeviceSerial": null,
                "recentDeviceName": null,
                "recentDeviceMac": null,
                "ssid": null,
                "vlan": null,
                "switchport": null,
                "sent": null,
                "recv": null,
                "status": null,
                "ssidId": null,
                "vlanId": null,
                "deviceId": null,
                "integrationPointId": null
            },
            "traffic": 1154714
        },
        {
            "client": {
                "id": null,
                "kpiName": null,
                "organizationId": null,
                "networkId": null,
                "venueName": null,
                "venueId": null,
                "tenantId": null,
                "device": null,
                "time": null,
                "ext_id": null,
                "mac": "80:82:23:62:25:5a",
                "description": "d9092348-ee74-4639-afde-e3492b5d238b",
                "ip": null,
                "ip6": null,
                "user": null,
                "firstSeen": null,
                "lastSeen": null,
                "manufacturer": "Apple",
                "os": "Apple iPhone",
                "recentDeviceSerial": null,
                "recentDeviceName": null,
                "recentDeviceMac": null,
                "ssid": null,
                "vlan": null,
                "switchport": null,
                "sent": null,
                "recv": null,
                "status": null,
                "ssidId": null,
                "vlanId": null,
                "deviceId": null,
                "integrationPointId": null
            },
            "traffic": 1104169
        },
        {
            "client": {
                "id": null,
                "kpiName": null,
                "organizationId": null,
                "networkId": null,
                "venueName": null,
                "venueId": null,
                "tenantId": null,
                "device": null,
                "time": null,
                "ext_id": null,
                "mac": "40:5b:d8:cb:7a:db",
                "description": "DESKTOP-402V9LE",
                "ip": null,
                "ip6": null,
                "user": null,
                "firstSeen": null,
                "lastSeen": null,
                "manufacturer": "CHONGQING FUGUI...",
                "os": null,
                "recentDeviceSerial": null,
                "recentDeviceName": null,
                "recentDeviceMac": null,
                "ssid": null,
                "vlan": null,
                "switchport": null,
                "sent": null,
                "recv": null,
                "status": null,
                "ssidId": null,
                "vlanId": null,
                "deviceId": null,
                "integrationPointId": null
            },
            "traffic": 991522
        }
    ]
}
export const TRAFFIC_DEVIATION: any[] = [
    {
        "date": "2020-10-29",
        "value": 673753,
        "dayOfWeekStddev": 481145.4,
        "dayOfWeekMean": 604553.0,
        "outlier-type": null
    },
    {
        "date": "2020-10-30",
        "value": 21593,
        "dayOfWeekStddev": 3141.0032,
        "dayOfWeekMean": 21014.0,
        "outlier-type": null
    },
    {
        "date": "2020-10-31",
        "value": 21614,
        "dayOfWeekStddev": 120628.89,
        "dayOfWeekMean": 92688.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-01",
        "value": 246741,
        "dayOfWeekStddev": 336465.0,
        "dayOfWeekMean": 255330.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-02",
        "value": 699637,
        "dayOfWeekStddev": 317118.34,
        "dayOfWeekMean": 321731.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-03",
        "value": 24905,
        "dayOfWeekStddev": 203228.66,
        "dayOfWeekMean": 263339.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-04",
        "value": 1007867,
        "dayOfWeekStddev": 615995.3,
        "dayOfWeekMean": 661112.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-05",
        "value": 159332,
        "dayOfWeekStddev": 474394.5,
        "dayOfWeekMean": 548900.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-06",
        "value": 25329,
        "dayOfWeekStddev": 3246.9697,
        "dayOfWeekMean": 21630.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-07",
        "value": 27105,
        "dayOfWeekStddev": 36119.76,
        "dayOfWeekMean": 40487.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-08",
        "value": 445521,
        "dayOfWeekStddev": 136001.22,
        "dayOfWeekMean": 161779.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-09",
        "value": 483806,
        "dayOfWeekStddev": 304700.84,
        "dayOfWeekMean": 339739.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-10",
        "value": 275051,
        "dayOfWeekStddev": 192831.25,
        "dayOfWeekMean": 264640.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-11",
        "value": 1077781,
        "dayOfWeekStddev": 597603.8,
        "dayOfWeekMean": 702779.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-12",
        "value": 451502,
        "dayOfWeekStddev": 450985.97,
        "dayOfWeekMean": 538078.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-13",
        "value": 14215,
        "dayOfWeekStddev": 3781.563,
        "dayOfWeekMean": 20703.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-14",
        "value": 23355,
        "dayOfWeekStddev": 34638.94,
        "dayOfWeekMean": 38345.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-15",
        "value": 1050360,
        "dayOfWeekStddev": 382891.97,
        "dayOfWeekMean": 364799.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-16",
        "value": 479899,
        "dayOfWeekStddev": 293274.03,
        "dayOfWeekMean": 353755.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-17",
        "value": 107661,
        "dayOfWeekStddev": 189261.05,
        "dayOfWeekMean": 248942.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-18",
        "value": 2053935,
        "dayOfWeekStddev": 689595.4,
        "dayOfWeekMean": 825611.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-19",
        "value": 354184,
        "dayOfWeekStddev": 433203.6,
        "dayOfWeekMean": 519688.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-20",
        "value": 17725,
        "dayOfWeekStddev": 3703.6663,
        "dayOfWeekMean": 20372.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-21",
        "value": 22882,
        "dayOfWeekStddev": 33318.19,
        "dayOfWeekMean": 36627.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-22",
        "value": 435455,
        "dayOfWeekStddev": 365632.16,
        "dayOfWeekMean": 371865.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-23",
        "value": 482406,
        "dayOfWeekStddev": 283012.47,
        "dayOfWeekMean": 365450.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-24",
        "value": 772175,
        "dayOfWeekStddev": 231462.9,
        "dayOfWeekMean": 296509.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-25",
        "value": 622580,
        "dayOfWeekStddev": 662617.7,
        "dayOfWeekMean": 808692.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-26",
        "value": 354402,
        "dayOfWeekStddev": 417248.44,
        "dayOfWeekMean": 504662.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-27",
        "value": 15217,
        "dayOfWeekStddev": 3816.7546,
        "dayOfWeekMean": 19857.0,
        "outlier-type": null
    },
    {
        "date": "2020-11-28",
        "value": 22081,
        "dayOfWeekStddev": 2525.2656,
        "dayOfWeekMean": 23470.0,
        "outlier-type": null
    }
]
