import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {environment} from "../../../../environments/environment";
import {UrlResolverService} from "../../../shared/services/url-resolver-service";

@Injectable({
  providedIn: 'root'
})
export class ConfigInitService {
  private config: any;

//https://wkrzywiec.medium.com/step-by-step-guide-how-integrate-keycloak-with-angular-application-d96b05f7dfdd
  constructor(private httpClient: HttpClient) {
  }

  public getConfig(): Observable<any> {
    return this.httpClient
      .get(this.getEnvConfig(), {
        observe: 'response',
      })
      .pipe(
        catchError((error) => {
          console.log(error);
          return of(null);
        }),
        mergeMap((response) => {
          if (response && response.body) {
            this.config = response.body;
            return of(this.config);
          } else {
            return of(null);
          }
        }));
  }

  private getEnvConfig(): string {
    return environment.keyCloak.configFile;
  }
}
