import { ReqInfoMocked } from './req-info.mocked';
import { SearchResult } from '../../models/search.model';

export function getSearch(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
    let searchResult = {
        "result": [
            {
                "name": "Lyoli MDM",
                "id": 40,
                "type": "Venue",
                "child": {
                    "name": "Netop",
                    "id": 1,
                    "type": "Organization",
                    "child": {
                        "name": "DeLab,ENLabs,haha,Cisco Live US 2019,DevNet Sandbox,Forest City - Other",
                        "id": 2,
                        "type": "Tenant",
                        "child": null
                    }
                }
            },
            {
                "name": "Lyoli",
                "id": 1,
                "type": "Venue",
                "child": {
                    "name": "Netop",
                    "id": 1,
                    "type": "Organization",
                    "child": {
                        "name": "DeLab,ENLabs,haha,Cisco Live US 2019,DevNet Sandbox,Forest City - Other",
                        "id": 2,
                        "type": "Tenant",
                        "child": null
                    }
                }
            },
            {
                "name": "Igor Device 1",
                "id": 1,
                "type": "Device",
                "child": {
                    "name": "Netop",
                    "id": 1,
                    "type": "Organization",
                    "child": {
                        "name": "DeLab,ENLabs,haha,Cisco Live US 2019,DevNet Sandbox,Forest City - Other",
                        "id": 2,
                        "type": "Tenant",
                        "child": {
                            "name": "Lyoli",
                            "id": 1,
                            "type": "Venue",
                            "child": null
                        }
                    }
                }
            },
            {
                "name": "Igor Device 3",
                "id": 3,
                "type": "Device",
                "child": {
                    "name": "Netop",
                    "id": 1,
                    "type": "Organization",
                    "child": {
                        "name": "DeLab,ENLabs,haha,Cisco Live US 2019,DevNet Sandbox,Forest City - Other",
                        "id": 2,
                        "type": "Tenant",
                        "child": {
                            "name": "Lyoli",
                            "id": 1,
                            "type": "Venue",
                            "child": null
                        }
                    }
                }
            },
            {
                "name": "Igor Fabric",
                "id": 1,
                "type": "Fabric",
                "child": {
                    "name": "Netop",
                    "id": 1,
                    "type": "Organization",
                    "child": {
                        "name": "DeLab,ENLabs,haha,Cisco Live US 2019,DevNet Sandbox,Forest City - Other",
                        "id": 2,
                        "type": "Tenant",
                        "child": {
                            "name": "Lyoli",
                            "id": 1,
                            "type": "Venue",
                            "child": null
                        }
                    }
                }
            },
        ],
        "num_of_results": 5
    }
    return searchResult
}
