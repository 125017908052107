import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false; // Default: don't detach the route
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    // Default: no operation, since we're not detaching routes
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false; // Default: don't reattach a stored route
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null; // Default: don't retrieve any stored route
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return false;//future.routeConfig === curr.routeConfig; // Reuse the route if the route configuration is the same
  }
}
