import { OrganizationHierarchyTree } from '../../models/hierarchy.model';

export function getOrganizationTree(): any {
    return [
        {
            "value": {
                "id": 1,
                "name": "Netop",
                "parentOrg": null,
                "type": "MSP",
                "hierarchy": "1",
                "tenants": [
                    1,
                    2544,
                    2548
                ]
            },
            "type": "Organization",
            "nodes": [
                {
                    "value": {
                        "id": 44,
                        "name": "Clalit",
                        "parentOrg": 1,
                        "type": "MSP",
                        "hierarchy": "1,2",
                        "tenants": [
                            2
                        ]
                    },
                    "type": "Organization",
                    "nodes": [
                        {
                            "value": {
                                "id": 2,
                                "organization": 2,
                                "name": " MEIR_KFAR_SABA_102221854,NetOp",
                                "externalId": "671036344478205221,929872",
                                "lineOfBusiness": null,
                                "priority": "High",
                                "venues": [],
                                "fabrics": [],
                                "createdAt": 1577011648000
                            },
                            "type": "Tenant",
                            "nodes": [
                                {
                                    "value": {
                                        "id": 3,
                                        "organization": 2,
                                        "name": " MEIR_KFAR_SABA_102221854,NetOp",
                                        "externalId": "671036344478205221,929872",
                                        "lineOfBusiness": null,
                                        "priority": "High",
                                        "venues": [],
                                        "fabrics": [],
                                        "createdAt": 1577011648000
                                    },
                                    "type": "Tenant",
                                    "nodes": []
                                }
                            ]
                        }
                    ]
                },
                {
                    "value": {
                        "id": 11,
                        "organization": 1,
                        "name": "Netop-MSP-Tests,NetOp",
                        "externalId": "891927,751698",
                        "lineOfBusiness": "Network Operations",
                        "priority": "Medium",
                        "venues": [],
                        "fabrics": [],
                        "createdAt": 1573673380943
                    },
                    "type": "Tenant",
                    "nodes": []
                },
                {
                    "value": {
                        "id": 2548,
                        "organization": 1,
                        "name": "tenant16",
                        "externalId": null,
                        "lineOfBusiness": "MSP",
                        "priority": "Medium",
                        "venues": [],
                        "fabrics": [],
                        "createdAt": 1576758882336
                    },
                    "type": "Tenant",
                    "nodes": []
                },
                {
                    "value": {
                        "id": 2544,
                        "organization": 1,
                        "name": "tenant16-2",
                        "externalId": null,
                        "lineOfBusiness": "MSP",
                        "priority": "High",
                        "venues": [],
                        "fabrics": [],
                        "createdAt": 1576758704387
                    },
                    "type": "Tenant",
                    "nodes": []
                }
            ]
        }
    ]

}