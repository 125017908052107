import { ReqInfoMocked } from './req-info.mocked';
import { KpiValueChange } from '../../models/kpi.model';
import { EntityType } from '../../models/entity-type.enum';

export function getHealthStatus(reqInfo: ReqInfoMocked) {
    let entityType = (reqInfo as any).query.get("queryType")[0];
    let mock = {
        [EntityType.ORGANIZATION]: { total: 90, high: 10, medium: 80, change: "3" },
        [EntityType.TENANT]: { total: 90, high: 50, medium: 40, change: "3" },
        [EntityType.FABRIC]: { total: 90, high: 80, medium: 10, change: "-3" },
        [EntityType.VENUE]: { total: 90, high: 0, medium: 85, change: "3" },
        [EntityType.DEVICE]: { total: 60, high: 0, medium: 0, change: "3" },
    }
    // console.log("getHealthStatus returns %o", mock[entityType]);
    // console.log('getHealthStatus called with %o query type %o', reqInfo, (reqInfo as any).query.get("queryType"))
    return mock[entityType];
}
export function getCurrentKpi(reqInfo: ReqInfoMocked): KpiValueChange {
    return { value: 20, change: "2" }
    // {value:number,kpiValueChange:number(percentage, optional ) }
}
