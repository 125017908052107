import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {EnvironmentModel, EnvironmentType} from "./environments/environment.model";

if (environment.production) {
  enableProdMode();
}


if (!EnvironmentType.isLoggingAllowed(environment.environmentType)) {
  if (window) {
    window.console.log = window.console.warn = window.console.info = function () {
      // Don't log anything.
    };
  }
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
