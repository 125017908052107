import { InMemoryDbService, ResponseOptions, RequestInfoUtilities, ParsedRequestUrl } from 'angular-in-memory-web-api';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { EntityType } from '../../models/entity-type.enum';

import { getTenantsActions, getTenantsHealth } from './tenants-health-actions.mocked'
import { ReqInfoMocked } from './req-info.mocked';
import { getIssues, getIssuesCategory } from './issues.mocked';
import { getHealthTrend, getActionsTrend, getKpiTrend, getSplitKpiTrend, getKpiGrouped } from './trend.mocked';
import { getVenuesHealth, getVenuesActions } from './venues-health-actions.mocked';
import { getHealthStatus, getCurrentKpi } from './status.mocked';
import { getFabricsActions, getFabricsHealth } from './fabrics-health-actions.mocked';
import { getDevicesActions, getDevicesHealth } from './devices-health-actions.mocked';

import { getHierarcy } from './hierarchy.mocked';

import { singleVenue, fabricTopology, singleDevice, venueTopology, fabric } from './topology.mocked';
import { getSearch } from './search.mocked';
import { getSelectedFabric, putFabricConfiguration, fabricsMocked } from './fabrics.mocked';
import { getSingleTenant, postTenant, tenantSyncStatus, tenantsMocked } from './tenants.mocked';
import { getOrganizationTree } from './org-tree.mocked';
import { getAlerts } from './alerts.mocked';
import { BEZEQ_CAMERA } from './mocked-topologies/bezeq-camers.mocked';

import { getClients, getFailedClients } from './clients.mocked';
import { venueSyncStatus, venuesSummary } from './venues-health.mocked';
import { multiVendorClients } from './clients.mocked';
import { vpnMocked, trafficVPN, vpnStatus } from './vpn.mocked';
import { PoliciesMocked } from './policies.mocked';

import { getEvents } from './events.mocked';
import { scheduleBackupStatus, schedulingState } from './journal.mocked';
import { wrapWithPage } from './wrap-with-page';
import { getActionsList } from './dashboard.mocked';
import { tenantBandwidth, venueBandwidth, postVenueBandwidth, venueUtilization } from './bandwidth.mocked';
import { getSyncChanges } from './sync-changes.mocked';
import { templatesMocked } from './templates.mocked';
import { ssids, vlans } from './network.mocked';
import { TRAFFIC_DEVIATION, TOP_REPORTS_DEVICE_DATA, LOSS_LATENCY_REPORTS } from './traffic.mocked';
import { anomaliesMocked, deviceIssues, getDeviceIssuesList } from './anomalies.mocked';
import { organizations, roles, users, defaultAccess, singleUser, tenantUsers } from './orgnization.mocked';


export class InMemoryDataService implements InMemoryDbService {

  createDb() {
    if (environment.partialInMemory) {
      return {}
    }

    return {
      tenants: tenantsMocked, organizations, roles, venuesSummary, anomalies: anomaliesMocked, deviceIssues,
      vpnMocked, vpnStatus, trafficVPN,
      ssids, vlans, fabrics: fabricsMocked, templates: templatesMocked, fabricTopology, venueTopology,
      defaultAccess, singleVenue, singleDevice, users, tenantUsers,
      singleUser, fabric, multiVendorClients,
      tenantSyncStatus, venueSyncStatus, tenantBandwidth, venueBandwidth, venueUtilization, scheduleBackupStatus, schedulingState,
      BEZEQ_CAMERA,
      TRAFFIC_DEVIATION, TOP_REPORTS_DEVICE_DATA, LOSS_LATENCY_REPORTS, POLICIES: PoliciesMocked
    };
  }

  // from https://github.com/angular/in-memory-web-api/blob/master/src/app/hero-in-mem-data-override.service.ts
  // parseRequestUrl override
  // Do this to manipulate the request URL or the parsed result
  // into something your data store can handle.
  // This example turns a request for `/foo/heroes` into just `/heroes`.
  // It leaves other URLs untouched and forwards to the default parser.
  // It also logs the result of the default parser.
  parseRequestUrl(requestUrl: string, utils: RequestInfoUtilities): ParsedRequestUrl {
    /**
     * For adding a new collection you should add here a new regexp that matches the real url
     * as a key and a collection name from createDb as a value.
     */
    // tenantsMocked/${tenantId}/venues/summary
    let urls = {
      'tenants\\/\\d+\\/venues\\/summary': 'venuesSummary',
      'tenants\\/\\d+\\/venues\\/\\d+\\/ssids\\/summary': 'ssids',
      'tenants\\/\\d+\\/venues\\/\\d+\\/vlans\\/summary': 'vlans',
      // organizations/1/tenantsMocked
      'users\\/\\d+$': 'singleUser',
      'organizations\\/\\d+\\/tenants': 'tenants',
      'organization-settings\\/\\d+': 'organizationsTypes',
      'organizations\\/\\d+\\/users': 'users',
      'organizations\\/roles': 'roles',
      'organizations(\\/)?$': 'organizations',
      'tenants/roles': 'roles',
      'tenants\\/\\d+\\/users': 'tenantUsers',
      'tenants\\/\\d+\\/journal': 'MockedJournal',
      'tenants\\/\\d+\\/fabricTemplates': 'templates',
      // '\^\\d+\\/tenantsMocked': 'orgTenants',
      'tenants\\/\\d+\\/fabrics\\/summary': 'fabricsSummary',
      'venues\\/\\d+\\/fabrics': 'fabrics',
      // '/organization/:orgId/tenantsMocked-health',
      // 'tenantsMocked\\/\\d+\\/fabricsMocked\\/\\d+\\/topology': 'multivendorTopology10',
      'venues\\/\\d+/kpis': 'singleVenue',
      'tenants\\/\\d+/sync-statuses': 'tenantSyncStatus',
      'venues\\/\\d+/sync-status': 'venueSyncStatus',
      'venues\\/\\d+\\/topology\\/': 'EXAMPLE3',
      'venues\\/\\d+\\/devices\\/\\d+': 'singleDevice',
      'venues\\/\\d+\\/connected-clients\\/': 'BET_HERUT2_CLIENTS',
      "globalInfo\\/access": "defaultAccess",
      "tenants\\/\\d+\\/fabrics\\/\\d+\\/advancedParameters": "fabricAdvancedParameters",
      'tenants\\/\\d+\\/bandwidth': 'tenantBandwidth',
      'tenants\\/\\d+\\/schedulingState': 'schedulingState',
      'tenants\\/\\d+\\/scheduleBackup': 'scheduleBackupStatus',
      'tenants\\/\\d+\\/vpn/traffic': 'trafficVPN',
      'tenants\\/\\d+\\/vpn/status': 'vpnStatus',
      'tenants\\/\\d+\\/vpn': 'vpnMocked',

      'venues\\/\\d+\\/bandwidth': 'venueBandwidth',
      'venues\\/\\d+\\/utilization': 'venueUtilization',
      'insights\\/anomalies\\/\\w+\\/\\d+\\/summary': 'anomalies',
      'dashboard/\\d+\\/traffic': 'TRAFFIC_DEVIATION',
      'policies/\\d+\\/traffic': 'TRAFFIC_DEVIATION',
      // 'policies/tenant/\\d+\\/\\w+\\/\\d+\\': 'POLICIES',
      // 'dashboard/\\w+\\/\\d+\\/traffic/': 'TRAFFIC_DEVIATION',
      // 'dashboard/\\w+\\/\\d+\\/traffic/top/': 'TOP_REPORTS_DEVICE_DATA',
      // 'dashboard/\\w+\\/\\d+\\/KPI//\\w+\\/': 'LOSS_LATENCY_REPORTS',

      // 'dashboard\\/issues': 'issues',
      // 'dashboard\\/actions-trend': 'actionsTrend',
      // 'dashboard\\/health-trend': 'actionsTrend',
      // 'dashboard\\/health-status': 'healthStatus',
    }
    for (let regExpUrl in urls) {
      let re = new RegExp(regExpUrl);
      // console.log('request url %s', requestUrl)
      let doesUrlMatch = re.test(requestUrl);//url.match(urlRegExp);
      if (doesUrlMatch) {
        //console.log('the url matched reg exp %s url %s', regExpUrl, requestUrl)
        let collectionName = urls[regExpUrl];
        let newUrl = requestUrl.replace(re, collectionName);
        // console.log('new url %s', newUrl)
        let parsedUrl = utils.parseRequestUrl(newUrl);
        //parsedUrl = utils.parseRequestUrl(requestUrl)
        // parsedUrl.apiBase = environment.apiBaseUrl;
        parsedUrl.collectionName = collectionName;
        // TODO: this is clearly a hack, we should be able to configure api base in some normal way
        // leaving the id parsing in there
        // parsedUrl.id = ""
        // console.log('api base %o', parsedUrl)
        return parsedUrl;
      }
    }
    return utils.parseRequestUrl(requestUrl);;
  }
  getActions(reqInfo: ReqInfoMocked, urlParams: Array<string>) {
    let [url, entityType, entityId, ...rest] = urlParams;
    let qt = (reqInfo as any).query.get("queryType");
    if (qt) {
      let queryType = qt[0];
      let mock = {
        [EntityType.TENANT.toLowerCase()]: (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getTenantsActions(reqInfo, urlParams)),
        [EntityType.VENUE.toLowerCase()]: (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getVenuesActions(reqInfo, urlParams)),
        [EntityType.DEVICE.toLowerCase()]: (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getDevicesActions(reqInfo, urlParams)),
        [EntityType.FABRIC.toLowerCase()]: (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getFabricsActions(reqInfo, urlParams)),
      }
      return mock[queryType.toLowerCase()].call(this, reqInfo, urlParams);
    }
  }
  post(reqInfo: RequestInfo) {
    //
    // don't mock post if mocking on prod/stage
    if (environment.partialInMemory) {
      return undefined;
    }
    let urls = {
      'tenants\\/': postTenant,
      'venues\\/\\d+\\/bandwidth': postVenueBandwidth,
    }
    return this.mockReq(reqInfo, urls)
  }
  put(reqInfo: RequestInfo) {
    ;
    // don't mock post if mocking on prod/stage
    if (environment.partialInMemory) {
      return undefined;
    }
    let urls = {
      'fabric\\/(\\w+)\\/configurationEntities': putFabricConfiguration,
    }
    return this.mockReq(reqInfo, urls)
  }
  // intercept get method for all in memory requests.
  get(reqInfo: RequestInfo) {
    //
    let urls = {
      // 'insights\\/list\\/(\\w+)\\/(\\d+)': 'getActions',
      'dashboard\\/actions-trend': getActionsTrend,
    }
    if (!environment.partialInMemory) {
      // Full in memory mock
      urls = {
        ...urls,
        ...{
          'tenants\\/(\\d+)$': getSingleTenant,
          'organizations/tree': getOrganizationTree,
          'venues\\/\\d+\\/clients-summary\\/': getClients,
          'venues\\/\\d+\\/failed-clients\\/': getFailedClients,
          'tenants\\/(\\d+)\\/fabrics\\/(\\d)+/kpis': getSelectedFabric,
          'dashboard\\/issues': getIssues,
          'dashboard\\/health/trend': getHealthTrend,
          'dashboard\\/health/status': getHealthStatus,
          'kpis/\\w+\\/kpi-trend': getKpiTrend,
          'kpis/\\w+\\/current-kpi': getCurrentKpi,
          'kpis/\\w+\\/\\w+\\/\\d+\\/trend': getKpiTrend,
          'kpis/\\w+\\/\\w+\\/\\d+\\/split/trend': getSplitKpiTrend,
          'kpis/\\w+\\/\\w+\\/\\d+\\/grouped/\\w+\\/trend': getKpiGrouped,
          'kpis/\\w+\\/\\w+\\/\\d+\\/latest': getCurrentKpi,
          // health api
          'organization\\/\\d+\\/tenants-health': (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getTenantsHealth(reqInfo, urlParams)),
          'tenants\\/\\d+\\/venues-health': (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getVenuesHealth(reqInfo, urlParams)),
          'tenants\\/\\d+\\/devices-health': (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getDevicesHealth(reqInfo, urlParams)),
          'tenants\\/\\d+\\/fabrics-health': (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getFabricsHealth(reqInfo, urlParams)),
          'tenants\\/\\d+\\/changeLog': (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getSyncChanges(reqInfo, urlParams)),
          'tenants\\/\\d+\\/alerts': (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getAlerts(reqInfo, urlParams)),
          'tenants\\/\\d+\\/events': (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getEvents(reqInfo, urlParams)),
          // 'insights\\/list\\/(\\w+)\\/(\\d+)': (reqInfo: ReqInfo, urlParams: Array<string>) => wrapWithPage(getActionsList(reqInfo, urlParams)),
          "navigation\\/path": getHierarcy,
          "search\\?": getSearch,
          'insights\\/anomalies\\/(\\w+)\\/(\\d+)/(\\w+)': (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getDeviceIssuesList(reqInfo, urlParams)),
          'organizations/\\d+\\/device-issues': (reqInfo: ReqInfoMocked, urlParams: Array<string>) => wrapWithPage(getDeviceIssuesList(reqInfo, urlParams)),
        },
      };
    } else {
      // Partial in memory mock

      // [1] Special case for issues donut - in partialInMemory only by category
      // grouped issues data should be mocked but not the by severity one.
      let req: ReqInfoMocked = reqInfo as any as ReqInfoMocked;
      if (req.url.match(/dashboard\/issues/)) {
        let entityType: ('Severity' | 'Category') = (reqInfo as any).query.get("groupBy")[0];

        if (entityType == 'Severity') {
          return undefined
        }
        return this.mockReq(reqInfo, { urls, 'dashboard\\/issues': getIssuesCategory })
      }
      // [2] Another special case for actions:
      // The actions for devices or for venue (for venue we are showing the devices actions only too)
      // all the rest is mocked for now.
      let insightsRegExpUrl = 'insights\\/list\\/(\\w+)\\/(\\d+)';
      let insightsRegExp = new RegExp(insightsRegExpUrl);
      let matches = insightsRegExp.exec(req.url);

      if (matches) {
        let entityType = matches[1];
        entityType = entityType.toLowerCase();
        if (EntityType.TENANT.toLowerCase() == entityType ||
          EntityType.VENUE.toLowerCase() == entityType ||
          EntityType.DEVICE.toLowerCase() == entityType) {
          return undefined;
          //return this.mockReq(reqInfo, { urls, [insightsRegExpUrl]: 'getActions' })
        } else {
          urls = {
            ...urls,
            [insightsRegExpUrl]: 'getActions'
          }
        }
      }
    }
    return this.mockReq(reqInfo, urls)
  }
  mockReq(reqInfo: RequestInfo, urls: { [url: string]: any }) {
    let req: ReqInfoMocked = reqInfo as any as ReqInfoMocked;
    for (let regExpUrl in urls) {
      let re = new RegExp(regExpUrl);
      // console.log('request url %s', req.url)
      let doesUrlMatch = re.test(req.url);//url.match(urlRegExp);
      let urlParams = re.exec(req.url);//url.match(urlRegExp);
      if (doesUrlMatch) {
        //console.log('the url matched in "GET request" reg exp %s url %s', regExpUrl, req.url)
        let methodName = urls[regExpUrl];
        let method = (this[methodName] || methodName)
        if (method) {
          let data = method.call(this, reqInfo, urlParams);
          return req.utils.createResponse$(() => {
            const dataEncapsulation = req.utils.getConfig().dataEncapsulation;
            const options: ResponseOptions = data ?
              {
                body: dataEncapsulation ? { data } : data,
                status: 200
              } :
              {
                body: { error: `Data not found` },
                status: 404
              };
            return this.finishOptions(options, reqInfo);
          })
        }
      }
    }
    return undefined;
  }

  protected responseInterceptor(res: ResponseOptions, ri: RequestInfo): ResponseOptions {
    // console.log("request %o", ri);
    return res;
  }
  /////////// helpers ///////////////

  private finishOptions(options: ResponseOptions, { headers, url }: any) {
    options.statusText = 'OK';
    options.headers = headers;
    options.url = url;
    return options;
  }
  private generateData(func) {
    let startingDate = moment();
    /**
     * export class ActionsTrend {
      total: Array<{
          datatime: moment.Moment;
          value: number;
      }>;
      closed: Array<{ datatime: moment.Moment, value: number }>;
  }

     */

    let data = [];
    //let labels = [];
    for (let i = 0; i < 20; i++) {
      let d = startingDate.clone()
      d.add(2 * i, 'hours');
      data.push({
        datetime: d,
        value: func(d.unix())
      })
      //labels.push(d.format('YYYY MM DD'))
    }
    return data;
  }
}
