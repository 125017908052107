import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, NEVER } from 'rxjs';
import { AppVersion } from '../../models/app-version.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppVersionService {
    private http: HttpClient;
    constructor(handler: HttpBackend) {
        // Note that we are injecting and using HttpBackend for creating http client 
        // which will skip interceptors.
        this.http = new HttpClient(handler);
    }
    getAppVersion(): Observable<AppVersion> {
        if (!environment.versionCheckURL) {
            return NEVER;
        }
        return this.http.get<AppVersion>(environment.versionCheckURL + '?t=' + new Date().getTime())
    }
}